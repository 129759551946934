import React from 'react';
import {
  Container,
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  Divider
} from '@mui/material';
import {
  WhatsApp,
  Email,
  LocationOn,
  AccessTime
} from '@mui/icons-material';
import TiktokIcon from '../assets/icons/TiktokIcon';
import InstagramIcon from '../assets/icons/InstagramIcon';
import FacebookIcon from '../assets/icons/FacebookIcon';

const ContactPage: React.FC = () => {
  const socialLinks = [
    {
      name: 'TikTok',
      icon: <TiktokIcon sx={{ fontSize: { xs: 50, md: 32 } }} />,
      url: 'https://www.tiktok.com/',
      color: '#000000'
    },
    {
      name: 'Instagram',
      icon: <InstagramIcon sx={{ fontSize: { xs: 40, md: 32 } }} />,
      url: 'https://www.instagram.com/achikh_almghribi/shikh_rohani_maghribi',
      color: '#E4405F'
    },
    {
      name: 'Facebook',
      icon: <FacebookIcon sx={{ fontSize: { xs: 40, md: 32 } }} />,
      url: 'https://www.facebook.com/kanjor7',
      color: '#1877F2'
    }
  ];

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/212604010304', '_blank');
  };

  return (
    <Box 
      sx={{ 
        py: { xs: 4, md: 10 }, 
        backgroundColor: '#f8f8f8',
        minHeight: '100vh',
        width: '100%',
        overflowX: 'hidden'
      }}
    >
      <Container 
        maxWidth="lg"
        sx={{
          px: { xs: 2, md: 3 },
          mx: 'auto',
          width: '100%'
        }}
      >
        <Grid 
          container 
          spacing={3}
          sx={{ 
            width: '100%',
            m: 0,
            '& > .MuiGrid-item': {
              px: { xs: 0, md: 2 },
              width: '100%'
            }
          }}
        >
          {/* Left Column */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '1.8rem', md: '2.5rem' },
                fontWeight: 700,
                color: '#006633',
                mb: { xs: 2, md: 2 },
                fontFamily: 'Tajawal',
                textAlign: { xs: 'center', md: 'right' }
              }}
            >
              تواصل معنا
            </Typography>

            <Typography
              sx={{
                color: '#666',
                mb: { xs: 3, md: 4 },
                fontFamily: 'Tajawal',
                fontSize: { xs: '1rem', md: '1.2rem' },
                lineHeight: 1.8,
                textAlign: { xs: 'center', md: 'right' }
              }}
            >
              نحن هنا لمساعدتك في جميع استفساراتك واحتياجاتك الروحانية. يمكنك التواصل معنا عبر أي من وسائل الاتصال المتاحة.
            </Typography>

            <Box sx={{ mb: { xs: 4, md: 6 } }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: { xs: 2.5, md: 3 },
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      backgroundColor: 'white',
                      borderRadius: 2,
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: '0 8px 24px rgba(0,0,0,0.08)'
                      }
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: 'rgba(0,102,51,0.1)',
                        borderRadius: '50%',
                        p: { xs: 1.2, md: 1.5 },
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Email sx={{ color: '#006633', fontSize: { xs: 20, md: 24 } }} />
                    </Box>
                    <Box>
                      <Typography sx={{ fontFamily: 'Tajawal', fontWeight: 600, mb: 0.5 }}>
                        البريد الإلكتروني
                      </Typography>
                      <Typography sx={{ fontFamily: 'Tajawal', color: '#666' }}>
                        Soltan.ayos@gmail.com
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 3,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      backgroundColor: 'white',
                      borderRadius: 2
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: 'rgba(0,102,51,0.1)',
                        borderRadius: '50%',
                        p: 1.5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <LocationOn sx={{ color: '#006633', fontSize: 24 }} />
                    </Box>
                    <Box>
                      <Typography sx={{ fontFamily: 'Tajawal', fontWeight: 600, mb: 0.5 }}>
                        العنوان
                      </Typography>
                      <Typography sx={{ fontFamily: 'Tajawal', color: '#666' }}>
                        المغرب - الدار البيضاء
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    elevation={0}
                    sx={{
                      p: 3,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      backgroundColor: 'white',
                      borderRadius: 2
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: 'rgba(0,102,51,0.1)',
                        borderRadius: '50%',
                        p: 1.5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <AccessTime sx={{ color: '#006633', fontSize: 24 }} />
                    </Box>
                    <Box>
                      <Typography sx={{ fontFamily: 'Tajawal', fontWeight: 600, mb: 0.5 }}>
                        ساعات العمل
                      </Typography>
                      <Typography sx={{ fontFamily: 'Tajawal', color: '#666' }}>
                        من الإثنين إلى السبت: 9 صباحاً - 9 مساءً
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} md={6}>
            <Paper
              elevation={0}
              sx={{
                p: { xs: 2, md: 4 },
                backgroundColor: 'white',
                borderRadius: 2,
                height: '100%',
                width: '100%',
                boxSizing: 'border-box'
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: 'Tajawal',
                  fontWeight: 600,
                  color: '#006633',
                  mb: { xs: 3, md: 4 },
                  textAlign: 'center',
                  fontSize: { xs: '1.4rem', md: '1.5rem' }
                }}
              >
                وسائل التواصل الاجتماعي
              </Typography>

              <Grid container spacing={2} sx={{ mb: { xs: 3, md: 4 } }}>
                {socialLinks.map((social) => (
                  <Grid item xs={12} key={social.name}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: { xs: 2.5, md: 3 },
                        backgroundColor: 'white',
                        borderRadius: 2,
                        transition: 'all 0.3s ease',
                        border: '1px solid rgba(0,0,0,0.1)',
                        '&:hover': {
                          transform: 'translateY(-5px)',
                          boxShadow: '0 8px 24px rgba(0,0,0,0.1)'
                        }
                      }}
                    >
                      <a 
                        href={social.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: { xs: 3, md: 2 },
                            justifyContent: 'center'
                          }}
                        >
                          <Box
                            sx={{
                              color: social.color,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: { xs: '64px', md: '48px' },
                              height: { xs: '64px', md: '48px' },
                              borderRadius: '50%',
                              backgroundColor: `${social.color}10`,
                              transition: 'all 0.3s ease',
                              '&:hover': {
                                backgroundColor: `${social.color}20`,
                              }
                            }}
                          >
                            {social.icon}
                          </Box>
                          <Typography
                            sx={{
                              fontFamily: 'Tajawal',
                              fontWeight: 600,
                              color: '#333',
                              fontSize: { xs: '1.25rem', md: '1.1rem' }
                            }}
                          >
                            {social.name}
                          </Typography>
                        </Box>
                      </a>
                    </Paper>
                  </Grid>
                ))}
              </Grid>

              <Divider sx={{ my: 4 }} />

              <Box sx={{ textAlign: 'center' }}>
                <Typography
                  sx={{
                    fontFamily: 'Tajawal',
                    mb: 3,
                    color: '#666',
                    fontSize: '1.1rem'
                  }}
                >
                  للتواصل المباشر والاستشارات الفورية
                </Typography>

                <Button
                  variant="contained"
                  onClick={handleWhatsAppClick}
                  startIcon={<WhatsApp />}
                  sx={{
                    backgroundColor: '#25D366',
                    '&:hover': {
                      backgroundColor: '#128C7E'
                    },
                    fontFamily: 'Tajawal',
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    py: 1.5,
                    px: 4,
                    borderRadius: 2,
                    '& .MuiButton-startIcon': {
                      marginRight: 0,
                      marginLeft: '8px'
                    }
                  }}
                >
                  تواصل معنا عبر الواتساب
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ContactPage; 