import React, { useState } from 'react';
import { supabase } from '../utils/supabase';
import { Container, Paper, TextField, Button, Alert, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const { error: signInError } = await supabase.auth.signInWithPassword({
      email,
      password
    });

    if (signInError) {
      setError(signInError.message);
    } else {
      navigate('/editor');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Paper sx={{ p: 4 }}>
        <Typography variant="h4" sx={{ mb: 4, textAlign: 'right', fontFamily: 'Tajawal' }}>
          تسجيل الدخول
        </Typography>
        <form onSubmit={handleLogin}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              label="البريد الإلكتروني"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              fullWidth
              dir="rtl"
              InputProps={{ sx: { fontFamily: 'Tajawal' } }}
              InputLabelProps={{ sx: { fontFamily: 'Tajawal' } }}
            />
            <TextField
              label="كلمة المرور"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              fullWidth
              dir="rtl"
              InputProps={{ sx: { fontFamily: 'Tajawal' } }}
              InputLabelProps={{ sx: { fontFamily: 'Tajawal' } }}
            />
            {error && (
              <Alert severity="error" sx={{ fontFamily: 'Tajawal' }}>
                {error}
              </Alert>
            )}
            <Button 
              type="submit"
              variant="contained"
              sx={{ 
                bgcolor: '#006633',
                fontFamily: 'Tajawal',
                fontSize: '1.1rem',
                py: 1.5
              }}
            >
              دخول
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default Login; 