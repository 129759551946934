import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Container } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sheikh from '../assets/sheikh.png';

interface CarouselSlide {
  title: string;
  subtitle: string;
  description: string;
}

const slides: CarouselSlide[] = [
  {
    title: "الشيخ كنجور المغربي",
    subtitle: "خبير في العلاج الروحاني والطب البديل",
    description: "خبرة أكثر من ٢٥ عاماً في العلاج الروحاني والكشف عن الطاقات السلبية"
  },
  {
    title: "العلاج الروحاني",
    subtitle: "حلول روحانية لمشاكلك",
    description: "نقدم علاجات روحانية فعالة للتخلص من المشاكل النفسية والروحية"
  },
  {
    title: "الأعشاب المغربية",
    subtitle: "علاجات طبيعية أصيلة",
    description: "وصفات عشبية مغربية تقليدية مختارة بعناية لتحسين الصحة الجسدية والنفسية"
  },
  {
    title: "بوابة شيخي",
    subtitle: "خدمات روحانية متكاملة",
    description: "نقدم خدمات متكاملة في العلوم الروحانية والفلكية، بالإضافة إلى منتجات بخور مغربية أصيلة"
  },
  {
    title: "خدماتنا المميزة",
    subtitle: "حلول روحانية شاملة",
    description: "نوفر مجموعة متنوعة من الخدمات تشمل العلاج الروحاني، الفلك، والاستشارات الروحية"
  }
];

const HeroCarousel: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    rtl: true,
    arrows: false,
  };

  return (
    <Box sx={{ 
      width: '100vw',
      position: 'relative',
      right: 0,
      margin: 0,
      padding: 0,
      overflow: 'hidden',
      '& .slick-slider': {
        margin: 0,
        padding: 0,
      },
      '& .slick-list': {
        margin: 0,
        padding: 0,
      },
      '& .slick-track': {
        margin: 0,
        padding: 0,
      },
      '& .slick-slide': {
        margin: 0,
        padding: 0,
      },
      '& .slick-slide > div': {
        margin: 0,
        padding: 0,
      }
    }}>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <Box 
            key={index}
            sx={{
              margin: 0,
              padding: 0,
              width: '100vw'
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${sheikh})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: '80vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                margin: 0,
                padding: 0,
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'linear-gradient(45deg, rgba(0,102,51,0.95), rgba(0,102,51,0.9))',
                  opacity: 0.9,
                }
              }}
            >
              <Container maxWidth="lg">
                <Box
                  sx={{
                    textAlign: 'center',
                    color: 'white',
                    py: 8,
                    position: 'relative',
                    zIndex: 1
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: { xs: '2.5rem', md: '4rem' },
                      fontFamily: 'Tajawal',
                      fontWeight: 800,
                      mb: 2,
                      textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                    }}
                  >
                    {slide.title}
                  </Typography>
                  <Typography
                    variant="h2"
                    sx={{
                      fontSize: { xs: '1.5rem', md: '2rem' },
                      fontFamily: 'Tajawal',
                      fontWeight: 600,
                      mb: 3,
                      opacity: 0.9,
                    }}
                  >
                    {slide.subtitle}
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: { xs: '1.2rem', md: '1.5rem' },
                      fontFamily: 'Tajawal',
                      fontWeight: 500,
                      maxWidth: '800px',
                      mx: 'auto',
                      lineHeight: 1.8,
                      opacity: 0.8,
                    }}
                  >
                    {slide.description}
                  </Typography>
                </Box>
              </Container>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default HeroCarousel;
