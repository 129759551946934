import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import ArticleCard from '../components/ArticleCard';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../utils/supabase';

const ArticlesPage: React.FC = () => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      const { data, error } = await supabase
        .from('articles')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Error fetching articles:', error);
        return;
      }

      setArticles(data || []);
      setLoading(false);
    };

    fetchArticles();
  }, []);

  const handleArticleClick = (article: any) => {
    const urlTitle = article.title
      .replace(/ /g, '-')
      .replace(/[^\u0621-\u064A0-9-]/g, '');
    navigate(`/${urlTitle}`);
  };

  if (loading) {
    return null;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 6, textAlign: 'center' }}>
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '2rem', md: '2.5rem' },
            fontWeight: 700,
            color: '#006633',
            mb: 2,
            fontFamily: 'Tajawal',
          }}
        >
          مقالات الشيخ كنجور
        </Typography>
        <Typography
          sx={{
            fontSize: '1.1rem',
            color: '#666',
            fontFamily: 'Tajawal',
            maxWidth: '600px',
            mx: 'auto'
          }}
        >
          اكتشف مجموعة متنوعة من المقالات حول الروحانيات، العلاج بالطاقة، والحكمة التقليدية
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {articles.map((article) => (
          <Grid item xs={12} sm={6} md={4} key={article.id}>
            <ArticleCard
              article={{
                id: article.id,
                title: article.title,
                content: article.summary,
                image: article.image,
                date: article.date,
                author: article.author
              }}
              onClick={() => handleArticleClick(article)}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ArticlesPage; 