import React from 'react';
import { Container, Typography, Paper, Box, Button } from '@mui/material';
import { 
  LocalHospital,
  Spa,
  Diamond,
  WhatsApp 
} from '@mui/icons-material';
import { useParams } from 'react-router-dom';

const products = {
  'اسرار-بخور': {
    title: "أسرار البخور",
    icon: <LocalHospital sx={{ fontSize: '3rem', color: '#006633' }} />,
    description: [
      "البخور ليس مجرد رائحة، بل هو مفتاح للطاقة الإيجابية والروحانية. نقدم مجموعة مميزة من البخور المغربي المصنوع بعناية فائقة باستخدام أعشاب وزيوت طبيعية. كل منتج في مجموعتنا يعكس التراث المغربي العريق، حيث يُستخدم لتنقية الأجواء، جلب البركة، وإضفاء جو من الراحة النفسية.",
      "سواء كنت تبحث عن بخور للاسترخاء أو لتنفيذ طقوس روحانية، منتجاتنا تلبي جميع احتياجاتك. نصنع بخورنا وفق وصفات تقليدية سرية موروثة عبر الأجيال، لضمان تجربة استثنائية.",
      "تتنوع خياراتنا بين بخور للهيبة والقبول، بخور لتنقية الطاقة، وبخور لجلب السكينة. كل منتج يتم اختباره بعناية لضمان فعاليته وجودته العالية.",
      "تجربة البخور معنا ليست فقط لتحسين الجو، بل هي وسيلة للتواصل مع تراث عريق وروحانية حقيقية. اكتشف أسرار البخور اليوم ودع تأثيره يجلب لك السكينة والطاقة الإيجابية."
    ]
  },
  'عطور-واعشاب': {
    title: "عطور وأعشاب روحانية",
    icon: <Spa sx={{ fontSize: '3rem', color: '#006633' }} />,
    description: [
      "عطورنا وأعشابنا الروحانية مصممة لتعزيز التوازن النفسي والطاقة الإيجابية. نستخدم أجود المكونات الطبيعية المستخلصة من أعشاب نادرة وزيوت عطرية أصيلة لضمان جودة وفعالية كل منتج.",
      "كل عطر وأعشاب في مجموعتنا مستوحى من التقاليد المغربية القديمة، التي تمزج بين الجمال والروحانية. هذه المنتجات مثالية للاستخدام اليومي أو خلال طقوس خاصة لتنقية النفس والجسد.",
      "سواء كنت تبحث عن عطر روحاني مميز أو أعشاب تساعدك على التخلص من الطاقة السلبية، ستجد في مجموعتنا كل ما تحتاجه. منتجاتنا مصنوعة وفق معايير دقيقة لضمان تجربة استثنائية.",
      "دع عطورنا وأعشابنا الروحانية تصبح جزءًا من روتينك اليومي، واستمتع بتأثيرها الساحر الذي يجمع بين التوازن والجمال الداخلي."
    ]
  },
  'منتجات-اضافية': {
    title: "منتجات إضافية",
    icon: <Diamond sx={{ fontSize: '3rem', color: '#006633' }} />,
    description: [
      "إلى جانب البخور والعطور، نقدم مجموعة متنوعة من المنتجات الروحانية المصممة خصيصًا لتلبية احتياجاتك. من أدوات الطقوس التقليدية إلى هدايا مميزة تحمل لمسة من الأصالة والروحانية.",
      "منتجاتنا الإضافية تشمل أدوات مخصصة لتحصين النفس والبيت، وحلول مبتكرة للتخلص من الطاقات السلبية. نحرص على تقديم كل ما يساعدك في تعزيز راحتك النفسية والطاقة الإيجابية.",
      "نركز على تقديم منتجات تجمع بين الجودة العالية والتقاليد المغربية الأصيلة، مع لمسة حديثة تلائم احتياجات العصر. سواء كنت بحاجة إلى منتج للاستخدام الشخصي أو كهدية روحانية مميزة، ستجد خيارات تناسبك.",
      "استمتع بتجربة شاملة تعيد إليك التوازن الداخلي وتجعلك على اتصال دائم بالطاقة الإيجابية. منتجاتنا الإضافية هي اختيارك الأمثل لتحقيق هذا الهدف."
    ]
  }
};

const ProductPage: React.FC = () => {
  const { productId } = useParams<{ productId: string }>();
  const product = products[productId as keyof typeof products];
  const whatsappNumber = "212604010304";

  const handleWhatsAppClick = () => {
    const message = `السلام عليكم، أريد الاستفسار عن ${product.title}`;
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  if (!product) {
    return <div>المنتج غير موجود</div>;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={0} sx={{ p: { xs: 2, md: 4 }, borderRadius: 2 }}>
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          {product.icon}
          <Typography 
            variant="h1" 
            sx={{ 
              fontSize: { xs: '2rem', md: '2.5rem' },
              fontWeight: 700,
              color: '#006633',
              mt: 2,
              fontFamily: 'Tajawal'
            }}
          >
            {product.title}
          </Typography>
        </Box>

        <Box sx={{ mb: 6 }}>
          {product.description.map((paragraph, index) => (
            <Typography
              key={index}
              sx={{
                mb: 3,
                lineHeight: 1.8,
                fontSize: '1.1rem',
                color: '#333',
                textAlign: 'right',
                fontFamily: 'Tajawal'
              }}
            >
              {paragraph}
            </Typography>
          ))}
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            size="large"
            startIcon={<WhatsApp sx={{ ml: 1 }} />}
            onClick={handleWhatsAppClick}
            sx={{
              bgcolor: '#006633',
              fontFamily: 'Tajawal',
              fontSize: '1.1rem',
              py: 2,
              px: 6,
              '&:hover': {
                bgcolor: '#005528'
              }
            }}
          >
            استفسر عن المنتج
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ProductPage; 