import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Container, 
  Box, 
  Button, 
  IconButton, 
  Drawer, 
  List, 
  ListItem, 
  ListItemText,
  ListItemIcon,
  Collapse,
  Menu,
  MenuItem,
  useMediaQuery, 
  useTheme,
  Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link as RouterLink } from 'react-router-dom';
import { 
  Favorite,
  Block,
  RemoveRedEye,
  Security,
  Psychology,
  LocalHospital,
  Spa,
  Diamond,
  Book,
  MenuBook,
  Calculate,
  Article,
  EmojiObjects,
  Stars,
  ContactSupport,
  ExpandMore
} from '@mui/icons-material';

const menuItems = [
  {
    title: 'الرئيسية',
    path: '/',
  },
  {
    title: 'خدماتنا',
    path: '/خدماتنا',
    subMenu: [
      { title: 'جلب الحبيب', path: '/خدماتنا/جلب-الحبيب', icon: <Favorite /> },
      { title: 'ابطال السحر والعكوسات', path: '/خدماتنا/ابطال-السحر', icon: <Block /> },
      { title: 'علاج الحسد والعين', path: '/خدماتنا/علاج-الحسد', icon: <RemoveRedEye /> },
      { title: 'تحصين النفس والبيت', path: '/خدماتنا/تحصين-النفس', icon: <Security /> },
      { title: 'كشف روحاني مخصص', path: '/خدماتنا/كشف-روحاني', icon: <Psychology /> },
    ]
  },
  {
    title: 'منتجاتنا',
    path: '/منتجاتنا',
    subMenu: [
      { title: 'اسرار بخور', path: '/منتجاتنا/اسرار-بخور', icon: <LocalHospital /> },
      { title: 'عطور وأعشاب روحانية', path: '/منتجاتنا/عطور-واعشاب', icon: <Spa /> },
      { title: 'منتجات إضافية', path: '/منتجاتنا/منتجات-اضافية', icon: <Diamond /> },
    ]
  },
  {
    title: 'الرقية الشرعية',
    path: '/الرقية-الشرعية',
    subMenu: [
      { title: 'التحصين', path: '/الرقية-الشرعية/التحصين', icon: <Security /> },
      { title: 'اسرار الكتاب والسنة', path: '/الرقية-الشرعية/اسرار-الكتاب-والسنة', icon: <Book /> },
      { title: 'رقية الشيخ كنجور', path: '/الرقية-الشرعية/رقية-الشيخ-كنجور', icon: <MenuBook /> },
    ]
  },
  {
    title: 'حاسبات الكشف الروحاني',
    path: '/حاسبات',
    subMenu: [
      { title: 'حاسبة فك السحر والعكوسات', path: '/حاسبات/فك-السحر', icon: <Calculate /> },
      { title: 'حاسبة علاج الحسد والعين', path: '/حاسبات/علاج-الحسد', icon: <RemoveRedEye /> },
      { title: 'حاسبة التوافق الروحي', path: '/حاسبات/التوافق-الروحي', icon: <Favorite /> },
    ]
  },
  {
    title: 'مدونة الشيخ كنجور',
    path: '/مدونة',
    subMenu: [
      { title: 'مقالات الروحانيات', path: '/مقالات', icon: <Article /> },
      { title: 'العلاج بالطاقة والبخور', path: '/مدونة/العلاج-بالطاقة', icon: <EmojiObjects /> },
      { title: 'الفلك والأبراج', path: '/مدونة/الفلك-والابراج', icon: <Stars /> },
      { title: 'نصائح وإرشادات', path: '/مدونة/نصائح-وارشادات', icon: <ContactSupport /> },
    ]
  },
  {
    title: 'اتصل بنا',
    path: '/اتصل-بنا',
  },
  {
    title: 'سياسة الخصوصية',
    path: '/سياسة-الخصوصية',
  }
];

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openSubMenus, setOpenSubMenus] = useState<{ [key: string]: boolean }>({});
  const [menuAnchors, setMenuAnchors] = useState<{ [key: string]: HTMLElement | null }>({});

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, path: string) => {
    setMenuAnchors(prev => ({
      ...prev,
      [path]: event.currentTarget
    }));
  };

  const handleMenuClose = (path: string) => {
    setMenuAnchors(prev => ({
      ...prev,
      [path]: null
    }));
  };

  const handleSubMenuToggle = (path: string) => {
    setOpenSubMenus(prev => ({
      ...prev,
      [path]: !prev[path]
    }));
  };

  const drawer = (
    <List sx={{ 
      direction: 'rtl',
      py: 2,
      '& .MuiListItem-root': {
        borderBottom: '1px solid rgba(0,102,51,0.1)',
      }
    }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center',
        mb: 3,
        pt: 1
      }}>
        <Typography
          sx={{
            color: '#006633',
            fontFamily: 'Tajawal',
            fontWeight: 700,
            fontSize: '1.5rem',
            textAlign: 'center'
          }}
        >
          بوابة شيخي
        </Typography>
      </Box>

      {menuItems.map((item, index) => (
        <React.Fragment key={`${item.path}-${index}`}>
          <ListItem
            component={item.subMenu ? 'div' : RouterLink}
            to={item.subMenu ? undefined : item.path}
            onClick={() => item.subMenu ? handleSubMenuToggle(item.path) : handleDrawerToggle()}
            sx={{
              fontFamily: 'Tajawal',
              textAlign: 'right',
              py: 2,
              px: 3,
              color: '#006633',
              '&:hover': {
                backgroundColor: 'rgba(0,102,51,0.1)',
              },
              '& .MuiListItemText-primary': {
                fontFamily: 'Tajawal',
                fontSize: '1.1rem',
                fontWeight: 600,
                color: '#006633',
              }
            }}
          >
            <ListItemText primary={item.title} />
            {item.subMenu && (
              <ExpandMore
                sx={{
                  transform: openSubMenus[item.path] ? 'rotate(180deg)' : 'rotate(0)',
                  transition: '0.3s',
                  mr: 1,
                  color: '#006633',
                  fontSize: '1.5rem',
                }}
              />
            )}
          </ListItem>
          {item.subMenu && (
            <Collapse in={openSubMenus[item.path]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {item.subMenu.map((subItem, subIndex) => (
                  <ListItem
                    key={`${subItem.path}-${subIndex}`}
                    component={RouterLink}
                    to={subItem.path}
                    onClick={handleDrawerToggle}
                    sx={{
                      pr: 6,
                      pl: 3,
                      py: 1.5,
                      backgroundColor: 'rgba(0,102,51,0.05)',
                      borderBottom: 'none',
                      direction: 'rtl',
                      '&:hover': {
                        backgroundColor: 'rgba(0,102,51,0.1)',
                      },
                    }}
                  >
                    <ListItemIcon 
                      sx={{ 
                        minWidth: 40, 
                        color: '#006633',
                        ml: 2,
                        mr: 0,
                        '& .MuiSvgIcon-root': {
                          fontSize: '1.3rem'
                        }
                      }}
                    >
                      {subItem.icon}
                    </ListItemIcon>
                    <ListItemText 
                      primary={subItem.title}
                      sx={{
                        '& .MuiListItemText-primary': {
                          fontFamily: 'Tajawal',
                          fontSize: '1rem',
                          color: '#006633',
                          fontWeight: 500,
                          textAlign: 'right',
                        }
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar 
        position="sticky" 
        sx={{ 
          backgroundColor: 'rgba(255, 255, 255, 0.98)',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          backdropFilter: 'blur(10px)',
          height: { xs: '60px', md: 'auto' },
        }}
      >
        <Container maxWidth="lg">
          <Toolbar 
            sx={{ 
              justifyContent: 'space-between', 
              minHeight: { xs: '60px', md: '64px' },
              px: { xs: 1, md: 2 }
            }}
          >
            {/* Logo/Title */}
            <RouterLink to="/" style={{ textDecoration: 'none' }}>
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                ml: { xs: 0, md: '2rem' }
              }}>
                <Typography
                  sx={{
                    color: '#006633',
                    fontFamily: 'Tajawal',
                    fontWeight: 500,
                    fontSize: { xs: '0.8rem', md: '0.9rem' },
                    lineHeight: 1
                  }}
                >
                  بوابة
                </Typography>
                <Typography
                  sx={{
                    color: '#006633',
                    fontFamily: 'Tajawal',
                    fontWeight: 700,
                    fontSize: { xs: '1rem', md: '1.1rem' },
                    lineHeight: 1
                  }}
                >
                  شيخي
                </Typography>
              </Box>
            </RouterLink>

            {/* Desktop Menu */}
            <Box 
              sx={{ 
                display: { xs: 'none', md: 'flex' }, 
                gap: 2, 
                alignItems: 'center', 
                marginLeft: 'auto',
                overflow: 'auto'
              }}
            >
              {menuItems.map((item) => (
                <React.Fragment key={item.title}>
                  <Button
                    component={item.subMenu ? 'button' : RouterLink}
                    to={item.subMenu ? undefined : item.path}
                    onClick={item.subMenu ? (e: React.MouseEvent<HTMLButtonElement>) => handleMenuOpen(e, item.path) : undefined}
                    endIcon={item.subMenu ? <ExpandMore /> : undefined}
                    sx={{
                      color: '#006633',
                      fontSize: '0.95rem',
                      fontFamily: 'Tajawal',
                      fontWeight: 500,
                      px: 1.5,
                      minHeight: 48,
                      whiteSpace: 'nowrap',
                      '&:hover': {
                        backgroundColor: 'rgba(0,102,51,0.1)',
                      },
                      '& .MuiButton-endIcon': {
                        marginRight: 0,
                        marginLeft: '0.5rem'
                      }
                    }}
                  >
                    {item.title}
                  </Button>
                  {item.subMenu && (
                    <Menu
                      anchorEl={menuAnchors[item.path]}
                      open={Boolean(menuAnchors[item.path])}
                      onClose={() => handleMenuClose(item.path)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      sx={{
                        '& .MuiPaper-root': {
                          minWidth: 220,
                          backgroundColor: 'rgba(255, 255, 255, 0.95)',
                          backdropFilter: 'blur(10px)',
                          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                        }
                      }}
                    >
                      {item.subMenu.map((subItem) => (
                        <MenuItem
                          key={subItem.path}
                          component={RouterLink}
                          to={subItem.path}
                          onClick={() => handleMenuClose(item.path)}
                          sx={{
                            fontFamily: 'Tajawal',
                            fontSize: '0.9rem',
                            color: '#006633',
                            gap: 1.5,
                            py: 1,
                            whiteSpace: 'normal',
                            lineHeight: 1.2,
                            '&:hover': {
                              backgroundColor: 'rgba(0,102,51,0.1)',
                            },
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, minWidth: 0 }}>
                            {subItem.icon}
                            <span style={{ display: 'block' }}>{subItem.title}</span>
                          </Box>
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                </React.Fragment>
              ))}
            </Box>

            {/* Mobile Menu Button */}
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ 
                display: { md: 'none' },
                color: '#006633',
                '&:hover': {
                  backgroundColor: 'rgba(0,102,51,0.1)',
                }
              }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            width: { xs: '85%', sm: '360px' },
            backgroundColor: 'white',
            boxShadow: '-4px 0 10px rgba(0,0,0,0.1)',
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0,102,51,0.2)',
              borderRadius: '4px',
            },
          },
        }}
      >
        {drawer}
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1 }}>
        {children}
      </Box>

      {/* Footer */}
      <Box
        component="footer"
        sx={{
          py: { xs: 2, md: 3 },
          px: { xs: 1, md: 2 },
          mt: 'auto',
          backgroundColor: '#006633',
          color: 'white',
          textAlign: 'center',
          fontFamily: 'Tajawal',
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ 
            fontSize: { xs: '0.9rem', md: '1rem' },
            wordBreak: 'break-word'
          }}>
            جميع الحقوق محفوظة © {new Date().getFullYear()} بوابة شيخي
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;
