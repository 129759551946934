import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Alert,
  CircularProgress,
  Grid
} from '@mui/material';
import { Calculate } from '@mui/icons-material';

interface CalculationResult {
  compatibility: 'ضعيف' | 'متوسط' | 'جيد' | 'ممتاز';
  percentage: number;
  description: string;
  recommendations: string[];
}

const SpiritualCompatibilityCalculator: React.FC = () => {
  const [formData, setFormData] = useState({
    person1Name: '',
    person1BirthDate: '',
    person2Name: '',
    person2BirthDate: '',
    relationshipType: 'marriage',
    commonInterests: 'no',
    pastConflicts: 'no',
    familyApproval: 'no',
    spiritualAlignment: 'no'
  });

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<CalculationResult | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const calculateResult = (): CalculationResult => {
    let score = 0;
    
    // Calculate base score
    if (formData.commonInterests === 'yes') score += 25;
    if (formData.pastConflicts === 'no') score += 25;
    if (formData.familyApproval === 'yes') score += 25;
    if (formData.spiritualAlignment === 'yes') score += 25;

    // Determine compatibility level
    let compatibility: CalculationResult['compatibility'];
    let description: string;
    let recommendations: string[];

    if (score <= 25) {
      compatibility = 'ضعيف';
      description = 'التوافق الروحي منخفض ويحتاج إلى اهتمام خاص';
      recommendations = [
        'استشارة روحانية متخصصة',
        'العمل على تقوية الروابط الروحية',
        'تحسين التواصل والتفاهم المشترك',
        'البحث عن نقاط التوافق وتعزيزها'
      ];
    } else if (score <= 50) {
      compatibility = 'متوسط';
      description = 'هناك توافق روحي معقول مع إمكانية للتحسين';
      recommendations = [
        'تعزيز الجوانب الإيجابية في العلاقة',
        'العمل على فهم احتياجات الطرف الآخر',
        'المشاركة في أنشطة روحانية مشتركة',
        'تقوية الروابط العاطفية والروحية'
      ];
    } else if (score <= 75) {
      compatibility = 'جيد';
      description = 'التوافق الروحي جيد مع وجود أساس قوي للعلاقة';
      recommendations = [
        'الحفاظ على مستوى التواصل الجيد',
        'تطوير الأهداف المشتركة',
        'تعزيز الثقة المتبادلة',
        'الاستمرار في النمو الروحي المشترك'
      ];
    } else {
      compatibility = 'ممتاز';
      description = 'توافق روحي ممتاز يبشر بعلاقة ناجحة ومستقرة';
      recommendations = [
        'الحفاظ على هذا المستوى من التوافق',
        'مشاركة تجربتكم مع الآخرين',
        'وضع خطط مستقبلية مشتركة',
        'تعزيز الروابط الروحية القوية'
      ];
    }

    return { compatibility, percentage: score, description, recommendations };
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    // Simulate calculation delay
    setTimeout(() => {
      const calculationResult = calculateResult();
      setResult(calculationResult);
      setLoading(false);
    }, 1500);
  };

  return (
    <Box sx={{ py: { xs: 4, md: 6 }, backgroundColor: '#f8f8f8' }}>
      <Container maxWidth="md">
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '1.8rem', md: '2.2rem' },
            fontWeight: 700,
            textAlign: 'center',
            color: '#006633',
            mb: 2,
            fontFamily: 'Tajawal'
          }}
        >
          حاسبة التوافق الروحي
        </Typography>

        <Typography
          sx={{
            textAlign: 'center',
            color: '#666',
            mb: 4,
            fontFamily: 'Tajawal',
            fontSize: { xs: '1rem', md: '1.1rem' }
          }}
        >
          أدخل المعلومات المطلوبة لتحليل مدى التوافق الروحي بين الطرفين
        </Typography>

        <Paper
          elevation={0}
          sx={{
            p: { xs: 3, md: 4 },
            backgroundColor: 'white',
            borderRadius: 2
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="اسم الشخص الأول"
                    name="person1Name"
                    value={formData.person1Name}
                    onChange={handleChange}
                    required
                    InputLabelProps={{
                      sx: { fontFamily: 'Tajawal' }
                    }}
                    InputProps={{
                      sx: { fontFamily: 'Tajawal' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="تاريخ ميلاد الشخص الأول"
                    name="person1BirthDate"
                    type="date"
                    value={formData.person1BirthDate}
                    onChange={handleChange}
                    required
                    InputLabelProps={{
                      shrink: true,
                      sx: { fontFamily: 'Tajawal' }
                    }}
                    InputProps={{
                      sx: { fontFamily: 'Tajawal' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="اسم الشخص الثاني"
                    name="person2Name"
                    value={formData.person2Name}
                    onChange={handleChange}
                    required
                    InputLabelProps={{
                      sx: { fontFamily: 'Tajawal' }
                    }}
                    InputProps={{
                      sx: { fontFamily: 'Tajawal' }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="تاريخ ميلاد الشخص الثاني"
                    name="person2BirthDate"
                    type="date"
                    value={formData.person2BirthDate}
                    onChange={handleChange}
                    required
                    InputLabelProps={{
                      shrink: true,
                      sx: { fontFamily: 'Tajawal' }
                    }}
                    InputProps={{
                      sx: { fontFamily: 'Tajawal' }
                    }}
                  />
                </Grid>
              </Grid>

              <FormControl>
                <FormLabel sx={{ fontFamily: 'Tajawal', color: '#006633' }}>
                  نوع العلاقة
                </FormLabel>
                <RadioGroup
                  name="relationshipType"
                  value={formData.relationshipType}
                  onChange={handleChange}
                >
                  <FormControlLabel 
                    value="marriage" 
                    control={<Radio />} 
                    label="زواج"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="engagement" 
                    control={<Radio />} 
                    label="خطوبة"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel sx={{ fontFamily: 'Tajawal', color: '#006633' }}>
                  هل لديكما اهتمامات مشتركة؟
                </FormLabel>
                <RadioGroup
                  name="commonInterests"
                  value={formData.commonInterests}
                  onChange={handleChange}
                >
                  <FormControlLabel 
                    value="yes" 
                    control={<Radio />} 
                    label="نعم"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="no" 
                    control={<Radio />} 
                    label="لا"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel sx={{ fontFamily: 'Tajawal', color: '#006633' }}>
                  هل هناك موافقة من العائلتين؟
                </FormLabel>
                <RadioGroup
                  name="familyApproval"
                  value={formData.familyApproval}
                  onChange={handleChange}
                >
                  <FormControlLabel 
                    value="yes" 
                    control={<Radio />} 
                    label="نعم"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="no" 
                    control={<Radio />} 
                    label="لا"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel sx={{ fontFamily: 'Tajawal', color: '#006633' }}>
                  هل هناك توافق في التوجه الروحاني؟
                </FormLabel>
                <RadioGroup
                  name="spiritualAlignment"
                  value={formData.spiritualAlignment}
                  onChange={handleChange}
                >
                  <FormControlLabel 
                    value="yes" 
                    control={<Radio />} 
                    label="نعم"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="no" 
                    control={<Radio />} 
                    label="لا"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                </RadioGroup>
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <Calculate />}
                sx={{
                  py: 1.5,
                  backgroundColor: 'var(--primary-green)',
                  fontFamily: 'Tajawal',
                  fontSize: '1.1rem',
                  '& .MuiButton-startIcon': {
                    marginRight: 0,
                    marginLeft: '12px'
                  },
                  '&:hover': {
                    backgroundColor: 'var(--dark-green)'
                  }
                }}
              >
                {loading ? 'جاري التحليل...' : 'تحليل التوافق'}
              </Button>
            </Box>
          </form>

          {result && (
            <Box sx={{ mt: 4 }}>
              <Divider sx={{ mb: 3 }} />
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'Tajawal',
                  fontWeight: 600,
                  color: '#006633',
                  mb: 2
                }}
              >
                نتيجة التحليل
              </Typography>

              <Alert 
                severity={
                  result.compatibility === 'ممتاز' ? 'success' :
                  result.compatibility === 'جيد' ? 'info' :
                  result.compatibility === 'متوسط' ? 'warning' : 'error'
                }
                sx={{ mb: 2, fontFamily: 'Tajawal' }}
              >
                <Typography sx={{ fontWeight: 600, mb: 1, fontFamily: 'Tajawal' }}>
                  نسبة التوافق: {result.percentage}%
                </Typography>
                <Typography sx={{ fontWeight: 600, mb: 1, fontFamily: 'Tajawal' }}>
                  مستوى التوافق: {result.compatibility}
                </Typography>
                {result.description}
              </Alert>

              <Typography
                sx={{
                  fontFamily: 'Tajawal',
                  fontWeight: 600,
                  color: '#006633',
                  mb: 1
                }}
              >
                التوصيات:
              </Typography>
              <ul style={{ paddingRight: '20px' }}>
                {result.recommendations.map((rec, index) => (
                  <li key={index} style={{ fontFamily: 'Tajawal', marginBottom: '8px' }}>
                    {rec}
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default SpiritualCompatibilityCalculator; 