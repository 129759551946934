import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Breadcrumbs, Link, Paper, Button } from '@mui/material';
import { CalendarToday, Person, NavigateNext, Edit } from '@mui/icons-material';
import { supabase } from '../utils/supabase';

const ArticlePage = () => {
  const { title } = useParams<{ title: string }>();
  const navigate = useNavigate();
  const [article, setArticle] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const fetchArticle = async () => {
      const decodedTitle = title?.replace(/-/g, ' ');
      
      const { data, error } = await supabase
        .from('articles')
        .select('*')
        .ilike('title', decodedTitle || '')
        .single();

      if (error || !data) {
        navigate('/404');
        return;
      }

      setArticle(data);
      setLoading(false);
    };

    fetchArticle();
  }, [title, navigate]);

  useEffect(() => {
    const checkAdmin = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setIsAdmin(!!session);
    };
    checkAdmin();
  }, []);

  if (loading) {
    return null;
  }

  if (!article) {
    return null;
  }

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return new Date(dateString).toLocaleDateString('ar-SA', options);
  };

  const formatContent = (content: string) => {
    // Replace multiple newlines with proper paragraph breaks
    const withParagraphs = content
      .split(/\n\s*\n/)
      .map(paragraph => `<p>${paragraph.trim()}</p>`)
      .join('');

    // Style images
    const withStyledImages = withParagraphs.replace(
      /<img(.*?)>/g, 
      '<img$1 style="max-width: 100%; height: auto; margin: 20px auto; display: block; border-radius: 8px; box-shadow: 0 4px 12px rgba(0,0,0,0.1);">'
    );

    return withStyledImages;
  };

  return (
    <Box sx={{ 
      py: { xs: 4, md: 6 }, 
      backgroundColor: '#f8f8f8',
      minHeight: '100vh'
    }}>
      <Container maxWidth="lg">
        <Paper 
          elevation={0} 
          sx={{ 
            p: { xs: 2, md: 4 },
            borderRadius: 2,
            backgroundColor: 'white',
            overflow: 'hidden'
          }}
        >
          <Breadcrumbs 
            separator={<NavigateNext fontSize="small" />} 
            aria-label="breadcrumb"
            sx={{ mb: 3, direction: 'rtl' }}
          >
            <Link color="inherit" href="/" sx={{ fontFamily: 'Tajawal' }}>
              الرئيسية
            </Link>
            <Link color="inherit" href="/مقالات" sx={{ fontFamily: 'Tajawal' }}>
              المقالات
            </Link>
            <Typography color="text.primary" sx={{ fontFamily: 'Tajawal' }}>
              {article.title}
            </Typography>
          </Breadcrumbs>

          {/* Featured Image */}
          <Box sx={{ 
            position: 'relative',
            width: '100%',
            height: { xs: '200px', sm: '300px', md: '400px' },
            mb: 4,
            borderRadius: 2,
            overflow: 'hidden',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
          }}>
            <img
              src={article.image}
              alt={article.title}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center'
              }}
            />
          </Box>

          {/* Article Header */}
          <Box sx={{ mb: 4 }}>
            <Typography 
              variant="h1" 
              sx={{ 
                fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.5rem' },
                fontFamily: 'Tajawal',
                fontWeight: 700,
                color: '#006633',
                textAlign: 'right',
                lineHeight: 1.3,
                mb: 3
              }}
            >
              {article.title}
            </Typography>

            <Box sx={{ 
              display: 'flex', 
              gap: 3, 
              color: 'text.secondary',
              direction: 'rtl',
              flexWrap: 'wrap'
            }}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1,
                color: '#666'
              }}>
                <CalendarToday sx={{ fontSize: 20 }} />
                <Typography sx={{ 
                  fontFamily: 'Tajawal',
                  fontSize: '0.95rem'
                }}>
                  {formatDate(article.date)}
                </Typography>
              </Box>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 1,
                color: '#666'
              }}>
                <Person sx={{ fontSize: 20 }} />
                <Typography sx={{ 
                  fontFamily: 'Tajawal',
                  fontSize: '0.95rem'
                }}>
                  {article.author}
                </Typography>
              </Box>
            </Box>
          </Box>

          {/* Admin Edit Button */}
          {isAdmin && (
            <Box sx={{ mb: 4, display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                startIcon={<Edit />}
                onClick={() => navigate(`/editor/${article.id}`)}
                sx={{
                  color: '#006633',
                  borderColor: '#006633',
                  fontFamily: 'Tajawal',
                  '&:hover': {
                    backgroundColor: 'rgba(0,102,51,0.05)',
                    borderColor: '#006633'
                  }
                }}
              >
                تعديل المقال
              </Button>
            </Box>
          )}

          {/* Article Content */}
          <Typography 
            variant="body1" 
            component="div"
            sx={{ 
              lineHeight: 2.2,
              fontSize: { xs: '1.1rem', md: '1.2rem' },
              fontFamily: 'Tajawal',
              color: '#333',
              textAlign: 'right',
              direction: 'rtl',
              '& p': { 
                mb: 4,
                whiteSpace: 'pre-line'
              },
              '& img': {
                maxWidth: '100%',
                height: 'auto',
                margin: '32px auto',
                display: 'block',
                borderRadius: 2,
                boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
              },
              '& strong': {
                color: '#006633',
                fontWeight: 700
              },
              '& em': {
                color: '#666',
                fontStyle: 'italic'
              },
              '& h2, & h3': {
                color: '#006633',
                fontWeight: 700,
                fontSize: { xs: '1.5rem', md: '1.8rem' },
                mb: 3,
                mt: 5
              }
            }}
            dangerouslySetInnerHTML={{ __html: formatContent(article.content) }}
          />
        </Paper>
      </Container>
    </Box>
  );
};

export default ArticlePage;
