import React, { useEffect, useRef, useState } from 'react';
import { Container, Typography, Paper, Box, Button, IconButton } from '@mui/material';
import { PlayArrow, Pause, WhatsApp, VolumeUp } from '@mui/icons-material';
import kanjourImage from '../assets/kanjour.jpg';
import kanjourAudio from '../assets/kanjour.mp3';

const RuqyaKanjour: React.FC = () => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const whatsappNumber = "212604010304";

  useEffect(() => {
    // Auto-play when component mounts
    if (audioRef.current) {
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => setIsPlaying(true))
          .catch(error => console.log("Auto-play prevented:", error));
      }
    }
  }, []);

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleWhatsAppClick = () => {
    const message = "السلام ��ليكم، أريد الاستفسار عن رقية الشيخ كنجور";
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={0} sx={{ p: { xs: 2, md: 6 }, borderRadius: 2 }}>
        {/* Header Section */}
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Typography 
            variant="h1" 
            sx={{ 
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              fontWeight: 700,
              color: '#006633',
              mb: 2,
              fontFamily: 'Tajawal'
            }}
          >
            رقية الشيخ كنجور
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '1.5rem', md: '2rem' },
              fontWeight: 500,
              color: '#666',
              fontFamily: 'Tajawal'
            }}
          >
            برنامجك الروحاني للشفاء والحماية
          </Typography>
        </Box>

        {/* Image Section */}
        <Box 
          sx={{ 
            position: 'relative',
            height: { xs: '250px', sm: '350px', md: '450px' },
            mb: 6,
            borderRadius: 4,
            overflow: 'hidden'
          }}
        >
          <Box
            component="img"
            src={kanjourImage}
            alt="الشيخ كنجور"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: 4
            }}
          />
        </Box>

        {/* Description */}
        <Typography
          sx={{
            mb: 6,
            lineHeight: 2,
            fontSize: '1.2rem',
            color: '#333',
            textAlign: 'right',
            fontFamily: 'Tajawal'
          }}
        >
          رقية الشيخ كنجور هي وسيلة مميزة تجمع بين الرقية الشرعية والنصوص المستمدة من القرآن الكريم والسنة النبوية، مع خبرة طويلة في معالجة السحر، الحسد، والعين. تم تصميم الرقية لتناسب جميع الحالات ولتكون علاجًا روحانيًا فعالًا يعيد إليك الراحة النفسية والطاقة الإيجابية.
        </Typography>

        {/* Audio Player */}
        <Box 
          sx={{ 
            mb: 8,
            p: 4,
            backgroundColor: 'rgba(0,102,51,0.05)',
            borderRadius: 4,
            textAlign: 'center'
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: '1.8rem',
              fontWeight: 600,
              color: '#006633',
              mb: 4,
              fontFamily: 'Tajawal'
            }}
          >
            استمع للرقية الشرعية
          </Typography>
          
          <audio ref={audioRef} src={kanjourAudio} />
          
          <IconButton 
            onClick={handlePlayPause}
            sx={{ 
              bgcolor: '#006633',
              color: 'white',
              p: 3,
              '&:hover': {
                bgcolor: '#005528'
              }
            }}
          >
            {isPlaying ? <Pause sx={{ fontSize: '2rem' }} /> : <PlayArrow sx={{ fontSize: '2rem' }} />}
          </IconButton>

          <Box sx={{ mt: 4 }}>
            <Typography
              variant="h4"
              sx={{
                fontSize: '1.4rem',
                fontWeight: 600,
                color: '#006633',
                mb: 3,
                fontFamily: 'Tajawal'
              }}
            >
              كيفية الاستماع لرقية الشيخ كنجور:
            </Typography>
            
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 2,
              maxWidth: '600px',
              mx: 'auto',
              textAlign: 'right'
            }}>
              {[
                "تأكد من أنك في مكان هادئ ومريح بعيدًا عن الضوضاء.",
                "قم بتشغيل الرقية الشرعية عبر مشغل الصوت الموجود في الصفحة.",
                "اجلس أو استلقِ في وضعية مريحة وأغلق عينيك أثناء الاستماع.",
                "ركّز نيتك على الشفاء أو الحماية، وردد الأدعية في قلبك مع كلمات الرقية."
              ].map((instruction, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    backgroundColor: 'white',
                    p: 3,
                    borderRadius: 2,
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                  }}
                >
                  <Typography sx={{ 
                    fontFamily: 'Tajawal',
                    fontSize: '1.1rem'
                  }}>
                    {instruction}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        {/* CTA Button */}
        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            size="large"
            startIcon={<WhatsApp sx={{ ml: 1 }} />}
            onClick={handleWhatsAppClick}
            sx={{
              bgcolor: '#006633',
              fontFamily: 'Tajawal',
              fontSize: '1.2rem',
              py: 2,
              px: 8,
              borderRadius: 2,
              boxShadow: '0 4px 14px rgba(0,102,51,0.2)',
              '&:hover': {
                bgcolor: '#005528',
                transform: 'translateY(-2px)',
                transition: 'all 0.3s ease'
              }
            }}
          >
            احصل على رقية مخصصة
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default RuqyaKanjour; 