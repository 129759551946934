import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Paper, 
  TextField, 
  Button, 
  Box, 
  Typography,
  Snackbar,
  Alert,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip
} from '@mui/material';
import { Delete, Edit, Save, ArrowBack, Image, FormatBold, FormatItalic } from '@mui/icons-material';
import { supabase } from '../utils/supabase';
import { useNavigate, useParams } from 'react-router-dom';

const insertAtCursor = (field: HTMLTextAreaElement, text: string) => {
  const startPos = field.selectionStart;
  const endPos = field.selectionEnd;
  const beforeText = field.value.substring(0, startPos);
  const afterText = field.value.substring(endPos);
  field.value = beforeText + text + afterText;
  field.focus();
  field.selectionStart = startPos + text.length;
  field.selectionEnd = startPos + text.length;
};

const ArticleEditor: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isEditMode = Boolean(id);

  const [article, setArticle] = useState({
    title: '',
    author: 'الشيخ كنجور',
    date: new Date().toISOString(),
    image: '',
    summary: '',
    content: '',
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (isEditMode) {
      fetchArticle();
    }
  }, [id]);

  const fetchArticle = async () => {
    const { data, error } = await supabase
      .from('articles')
      .select('*')
      .eq('id', id)
      .single();

    if (error) {
      setError('خطأ في تحميل المقال');
      return;
    }

    if (data) {
      setArticle(data);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    
    try {
      if (isEditMode) {
        // Update existing article without updated_at field
        const { error: updateError } = await supabase
          .from('articles')
          .update({
            title: article.title,
            content: article.content,
            image: article.image,
            summary: article.summary,
            author: article.author
          })
          .eq('id', id);

        if (updateError) throw updateError;
        setSuccessMessage('تم تحديث المقال بنجاح');
      } else {
        // Create new article
        const { error: insertError } = await supabase
          .from('articles')
          .insert([{
            title: article.title,
            content: article.content,
            image: article.image,
            summary: article.summary,
            author: article.author,
            date: new Date().toISOString(),
          }]);

        if (insertError) throw insertError;
        setSuccessMessage('تم إنشاء المقال بنجاح');

        // Fetch the latest article to get its title
        const { data: latestArticle } = await supabase
          .from('articles')
          .select('*')
          .order('created_at', { ascending: false })
          .limit(1)
          .single();

        if (latestArticle) {
          const urlTitle = latestArticle.title
            .replace(/ /g, '-')
            .replace(/[^\u0621-\u064A0-9-]/g, '');
          setTimeout(() => {
            navigate(`/${urlTitle}`);
          }, 2000);
        }
      }

      setShowSuccess(true);

    } catch (err: any) {
      console.error('Error details:', err);
      setError(err.message || 'حدث خطأ في حفظ المقال');
      setShowSuccess(false);
    }
  };

  const handleDelete = async () => {
    try {
      const { error: deleteError } = await supabase
        .from('articles')
        .delete()
        .eq('id', id);

      if (deleteError) throw deleteError;

      setSuccessMessage('تم حذف المقال بنجاح');
      setShowSuccess(true);
      setDeleteDialogOpen(false);

      setTimeout(() => {
        navigate('/مقالات');
      }, 2000);

    } catch (err: any) {
      console.error('Error deleting article:', err);
      setError(err.message || 'حدث خطأ في حذف المقال');
    }
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/login');
  };

  const handleFormatClick = (format: string) => {
    const contentField = document.getElementById('content') as HTMLTextAreaElement;
    if (!contentField) return;

    const selectedText = contentField.value.substring(
      contentField.selectionStart,
      contentField.selectionEnd
    );

    switch (format) {
      case 'bold':
        insertAtCursor(contentField, `<strong>${selectedText}</strong>`);
        break;
      case 'italic':
        insertAtCursor(contentField, `<em>${selectedText}</em>`);
        break;
      case 'image':
        insertAtCursor(contentField, '\n<img src="رابط-الصورة-هنا" alt="وصف الصورة" />\n');
        break;
      case 'newline':
        insertAtCursor(contentField, '\n\n');
        break;
    }

    setArticle({
      ...article,
      content: contentField.value
    });
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button 
            onClick={() => navigate('/مقالات')}
            variant="outlined"
            startIcon={<ArrowBack />}
            sx={{ 
              fontFamily: 'Tajawal',
              color: '#006633',
              borderColor: '#006633'
            }}
          >
            العودة للمقالات
          </Button>
          {isEditMode && (
            <Button 
              onClick={() => setDeleteDialogOpen(true)}
              variant="outlined"
              color="error"
              startIcon={<Delete />}
              sx={{ fontFamily: 'Tajawal' }}
            >
              حذف المقال
            </Button>
          )}
        </Box>
        <Button 
          onClick={handleLogout}
          variant="outlined"
          sx={{ 
            fontFamily: 'Tajawal',
            color: '#006633',
            borderColor: '#006633'
          }}
        >
          تسجيل الخروج
        </Button>
      </Box>

      <Typography 
        variant="h4" 
        sx={{ 
          textAlign: 'right',
          fontFamily: 'Tajawal',
          color: '#006633',
          mb: 4
        }}
      >
        {isEditMode ? 'تعديل المقال' : 'إضافة مقال جديد'}
      </Typography>

      <Paper elevation={1} sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              label="عنوان المقال"
              value={article.title}
              onChange={(e) => setArticle({ ...article, title: e.target.value })}
              required
              fullWidth
              dir="rtl"
              InputProps={{ sx: { fontFamily: 'Tajawal' } }}
              InputLabelProps={{ sx: { fontFamily: 'Tajawal' } }}
            />

            <TextField
              label="رابط الصورة"
              value={article.image}
              onChange={(e) => setArticle({ ...article, image: e.target.value })}
              required
              fullWidth
              dir="rtl"
              InputProps={{ sx: { fontFamily: 'Tajawal' } }}
              InputLabelProps={{ sx: { fontFamily: 'Tajawal' } }}
              helperText="يمكنك رفع الصورة على موقع مثل imgur.com والحصول على الرابط"
            />

            <TextField
              label="الخص المقال"
              value={article.summary}
              onChange={(e) => setArticle({ ...article, summary: e.target.value })}
              required
              fullWidth
              multiline
              rows={3}
              dir="rtl"
              InputProps={{ sx: { fontFamily: 'Tajawal' } }}
              InputLabelProps={{ sx: { fontFamily: 'Tajawal' } }}
            />

            <Box sx={{ mb: 3 }}>
              <Typography sx={{ mb: 2, fontFamily: 'Tajawal', color: '#006633' }}>
                أدوات التنسيق:
              </Typography>
              <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                <Tooltip title="خط عريض">
                  <IconButton 
                    onClick={() => handleFormatClick('bold')}
                    sx={{ color: '#006633' }}
                  >
                    <FormatBold />
                  </IconButton>
                </Tooltip>
                <Tooltip title="خط مائل">
                  <IconButton 
                    onClick={() => handleFormatClick('italic')}
                    sx={{ color: '#006633' }}
                  >
                    <FormatItalic />
                  </IconButton>
                </Tooltip>
                <Tooltip title="إضافة صورة">
                  <IconButton 
                    onClick={() => handleFormatClick('image')}
                    sx={{ color: '#006633' }}
                  >
                    <Image />
                  </IconButton>
                </Tooltip>
              </Box>

              <TextField
                id="content"
                label="محتوى المقال"
                value={article.content}
                onChange={(e) => setArticle({ ...article, content: e.target.value })}
                required
                fullWidth
                multiline
                rows={15}
                dir="rtl"
                InputLabelProps={{ sx: { fontFamily: 'Tajawal' } }}
                InputProps={{
                  sx: { 
                    fontFamily: 'Tajawal',
                    lineHeight: 1.8,
                    '& textarea': {
                      whiteSpace: 'pre-wrap'
                    }
                  }
                }}
                helperText="يمكنك استخدام أدوات التنسيق أعلاه لتنسيق النص. استخدم Enter مرتين لإضافة مسافة بين الفقرات."
              />
            </Box>

            {error && (
              <Alert severity="error" sx={{ mt: 2, fontFamily: 'Tajawal' }}>
                {error}
              </Alert>
            )}

            <Button 
              type="submit"
              variant="contained"
              startIcon={isEditMode ? <Save /> : null}
              sx={{ 
                mt: 2,
                bgcolor: '#006633',
                fontFamily: 'Tajawal',
                fontSize: '1.1rem',
                py: 1.5,
                width: '100%'
              }}
            >
              {isEditMode ? 'حفظ التغييرات' : 'إنشاء المقال'}
            </Button>
          </Box>
        </form>
      </Paper>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        dir="rtl"
      >
        <DialogTitle sx={{ fontFamily: 'Tajawal' }}>
          تأكيد حذف المقال
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontFamily: 'Tajawal' }}>
            هل أنت متأكد من حذف هذا المقال؟ لا يمكن التراجع عن هذا الإجراء.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteDialogOpen(false)}
            sx={{ fontFamily: 'Tajawal' }}
          >
            إلغاء
          </Button>
          <Button 
            onClick={handleDelete}
            color="error"
            sx={{ fontFamily: 'Tajawal' }}
          >
            حذف
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={showSuccess} 
        autoHideDuration={3000} 
        onClose={() => setShowSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert 
          severity="success" 
          sx={{ 
            width: '100%', 
            fontFamily: 'Tajawal',
            fontSize: '1.1rem'
          }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ArticleEditor; 