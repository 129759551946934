import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './pages/HomePage';
import ArticlePage from './pages/ArticlePage';
import ArticleEditor from './pages/ArticleEditor';
import Login from './pages/Login';
import ServicePage from './pages/ServicePage';
import ProductPage from './pages/ProductPage';
import RuqyaPage from './pages/RuqyaPage';
import RuqyaKanjour from './pages/RuqyaKanjour';
import ArticlesPage from './pages/ArticlesPage';
import BlogPage from './pages/BlogPage';
import ContactPage from './pages/ContactPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import MagicRemovalCalculator from './pages/calculators/MagicRemovalCalculator';
import EvilEyeCalculator from './pages/calculators/EvilEyeCalculator';
import SpiritualCompatibilityCalculator from './pages/calculators/SpiritualCompatibilityCalculator';
import './styles.css';
import { supabase } from './utils/supabase';

const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: 'Noto Naskh Arabic, serif',
  },
  palette: {
    primary: {
      main: '#1a4d2e',
    },
    secondary: {
      main: '#c5a880',
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Layout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/:title" element={<ArticlePage />} />
            <Route path="/login" element={<Login />} />
            <Route 
              path="/editor" 
              element={
                <ProtectedRoute>
                  <ArticleEditor />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/editor/:id" 
              element={
                <ProtectedRoute>
                  <ArticleEditor />
                </ProtectedRoute>
              } 
            />
            <Route path="/خدماتنا/:serviceId" element={<ServicePage />} />
            <Route path="/منتجاتنا/:productId" element={<ProductPage />} />
            <Route path="/الرقية-الشرعية/:pageId" element={<RuqyaPage />} />
            <Route path="/الرقية-الشرعية/رقية-الشيخ-كنجور" element={<RuqyaKanjour />} />
            <Route path="/مقالات" element={<ArticlesPage />} />
            <Route path="/مدونة/العلاج-بالطاقة" element={<BlogPage pageId="العلاج-بالطاقة" />} />
            <Route path="/مدونة/الفلك-والابراج" element={<BlogPage pageId="الفلك-والابراج" />} />
            <Route path="/مدونة/نصائح-وارشادات" element={<BlogPage pageId="نصائح-وارشادات" />} />
            <Route path="/اتصل-بنا" element={<ContactPage />} />
            <Route path="/سياسة-الخصوصية" element={<PrivacyPolicy />} />
            <Route path="/حاسبات/فك-السحر" element={<MagicRemovalCalculator />} />
            <Route path="/حاسبات/علاج-الحسد" element={<EvilEyeCalculator />} />
            <Route path="/حاسبات/التوافق-الروحي" element={<SpiritualCompatibilityCalculator />} />
          </Routes>
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

// Protected Route component
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const [session, setSession] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      if (!session) navigate('/login');
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (!session) navigate('/login');
    });

    return () => subscription.unsubscribe();
  }, [navigate]);

  if (!session) {
    return null;
  }

  return <>{children}</>;
};

export default App;
