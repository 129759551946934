import React from 'react';
import { Container, Typography, Paper, Box, Button, Stack } from '@mui/material';
import { 
  Favorite, 
  Block, 
  RemoveRedEye, 
  Security, 
  Psychology,
  WhatsApp 
} from '@mui/icons-material';
import { useParams } from 'react-router-dom';

const services = {
  'جلب-الحبيب': {
    title: "جلب الحبيب",
    icon: <Favorite sx={{ fontSize: '3rem', color: '#006633' }} />,
    description: [
      "الحب هو أساس الحياة، لكن أحيانًا قد نتعرض لمشاكل تبعد الحبيب عنا أو تجعل العلاقة تفقد انسجامها. إذا كنت تبحث عن طريقة لاستعادة الحب والتفاهم مع شريك حياتك، نحن هنا لنقدم لك الحل الأمثل. خدماتنا تعتمد على تقاليد روحانية أصيلة تساعد في إزالة الحواجز بين الأحبة.",
      "نعمل على إعادة الدفء للعلاقات بطرق مدروسة وآمنة تستند إلى التراث المغربي العريق. سواء كنت تعاني من الفراق أو البرود العاطفي، فإن طقوسنا الروحانية تركز على تعزيز المحبة وإعادة التواصل بين الطرفين.",
      "من خلال الجمع بين الرقية الشرعية والدعاء الخاص، نقدم خدمة فعالة تعيد السعادة والطمأنينة للعلاقة. خدماتنا تعتمد على السرية التامة واحترام خصوصية عملائنا، مما يضمن تجربة مريحة ومميزة.",
      "تواصل معنا الآن للحصول على استشارة مخصصة لحالتك. دعنا نساعدك في إعادة بناء جسور الحب وتحقيق السعادة التي تستحقها. نحن هنا لنكون دليلًا لك في رحلتك نحو علاقة أكثر استقرارًا وتفاهمًا."
    ],
    benefits: [
      "استعادة المحبة والتفاهم",
      "علاج البرود العاطفي",
      "تقوية الروابط العاطفية",
      "حل المشاكل الزوجية",
      "إزالة الحواجز النفسية",
      "تحسين التواصل بين الطرفين"
    ]
  },
  'ابطال-السحر': {
    title: "إبطال السحر والعكوسات",
    icon: <Block sx={{ fontSize: '3rem', color: '#006633' }} />,
    description: [
      "السحر والعكوسات من أكثر الأمور التي تؤثر على حياتنا بشكل سلبي، سواء في العمل، العلاقات، أو الصحة. إذا كنت تعاني من تعطيل مستمر أو شعور بالضيق بلا سبب واضح، فقد يكون السبب طاقة سلبية تؤثر عليك. خدماتنا تقدم لك الحل للتخلص من هذه الأعباء.",
      "نستخدم تقنيات روحانية شرعية لفك السحر وإزالة العكوسات التي تعطل حياتك. أساليبنا تعتمد على ممارسات مغربية متوارثة، تجمع بين الرقية الشرعية وأسرار الطاقة الإيجابية. هدفنا هو إعادة التوازن إلى حياتك ومساعدتك على المضي قدمًا بثقة.",
      "فك السحر يحتاج إلى دقة وخبرة لضمان نتائج فعالة. نحن نضمن لك السرية التامة والتعامل بحرفية واحترافية عالية. كل حالة يتم التعامل معها بعناية واهتمام خاص لتوفير الحل الأنسب.",
      "لا تدع السحر يسيطر على حياتك. تواصل معنا الآن لتبدأ رحلة التحرر واستعادة حياتك الطبيعية. نحن هنا لنكون سندًا لك ونساعدك على إزالة أي عائق يقف في طريق سعادتك."
    ],
    benefits: [
      "فك جميع أنواع السحر",
      "إزالة العكوسات والطاقات السلبية",
      "حماية مستقبلية من السحر",
      "استعادة التوازن النفسي",
      "علاج آثار السحر",
      "تحصين من العكوسات"
    ]
  },
  'علاج-الحسد': {
    title: "علاج الحسد والعين",
    icon: <RemoveRedEye sx={{ fontSize: '3rem', color: '#006633' }} />,
    description: [
      "الحسد والعين يمكن أن يكونا السبب وراء الكثير من المشاكل التي تواجهها في حياتك اليومية. من تراجع الصحة إلى تعطيل الرزق، يمكن لهذه الطاقات السلبية أن تؤثر بشكل كبير عليك وعلى محيطك. نحن نقدم خدمة مميزة لعلاج آثار الحسد والعين بطرق فعّالة وآمنة.",
      "علاجنا يعتمد على الرقية الشرعية الموثوقة التي تعمل على تنظيف الطاقة السلبية وإزالة أي تأثير سلبي ناتج عن الحسد. نستخدم تقنيات روحانية مدروسة لتحصين النفس وإعادة التوازن إلى حياتك.",
      "مع خدماتنا، ستشعر بتحسن فوري وراحة نفسية كبيرة. كل جلسة علاجية مصممة لتناسب حالتك الشخصية، مع ضمان السرية والاحترام الكامل. نسعى دائمًا لتحقيق أفضل النتائج التي تعيد إليك الهدوء والسكينة.",
      "لا تترك الحسد يؤثر على حياتك أكثر. تواصل معنا الآن لحماية نفسك وعائلتك من تأثير الطاقات السلبية والعيش بحياة مليئة بالإيجابية والتوفيق."
    ],
    benefits: [
      "علاج آثار العين والحسد",
      "تنظيف الطاقة السلبية",
      "تحصين مستمر",
      "راحة نفسية فورية",
      "جلسات مخصصة",
      "حماية مستدامة"
    ]
  },
  'تحصين-النفس': {
    title: "تحصين النفس والبيت",
    icon: <Security sx={{ fontSize: '3rem', color: '#006633' }} />,
    description: [
      "تحصين النفس والبيت أمر ضروري لضمان حياة مليئة بالراحة والسكينة. الطاقة السلبية يمكن أن تؤثر على حياتنا بطرق مختلفة، لكن مع خدمات التحصين التي نقدمها، يمكنك حماية نفسك ومحيطك من أي تأثير خارجي غير مرغوب فيه.",
      "نقدم تقنيات روحانية مغربية معروفة بفعاليتها في تنظيف المكان وتحصين الأفراد. طقوسنا تعتمد على الرقية الشرعية واستخدام أدوات روحانية مختارة بعناية لتوفير حماية مستدامة من أي طاقات سلبية.",
      "تحصين البيت يعمل على خلق أجواء من الهدوء والسلام، بينما تحصين النفس يمنحك قوة داخلية لمواجهة التحديات. مع خدماتنا، ستشعر بأنك في أمان دائم من أي تأثيرات سلبية قد تحيط بك.",
      "ابدأ رحلتك نحو الحماية الروحانية الآن. تواصل معنا لتحصل على استشارة مخصصة تناسب احتياجاتك. دعنا نساعدك على تحقيق راحة البال والحياة المليئة بالإيجابية والبركة."
    ],
    benefits: [
      "حماية شاملة للمنزل",
      "تحصين شخصي قوي",
      "طاقة إيجابية دائمة",
      "راحة بال مستمرة",
      "حماية الأسرة",
      "بيئة آمنة روحانياً"
    ]
  },
  'كشف-روحاني': {
    title: "كشف روحاني مخصص",
    icon: <Psychology sx={{ fontSize: '3rem', color: '#006633' }} />,
    description: [
      "الكشف الروحاني يمنحك الفرصة لفهم أسرار حياتك بشكل أعمق. من خلال الكشف، يمكننا تحديد العوائق التي تعطل تقدمك وتقديم الحلول المناسبة للتغلب عليها. خدماتنا موجهة لكل من يبحث عن إجابات دقيقة ومساعدة موثوقة.",
      "نحن نستخدم تقنيات الكشف المغربي التقليدي لتقديم تحليل مفصل لكل حالة. سواء كنت تعاني من مشاكل في العمل، الحب، أو الصحة، نساعدك على فهم مصدر المشكلة وتقديم حلول فعّالة ومخصصة.",
      "كل كشف يتم التعامل معه بسرية تامة واحترافية عالية. هدفنا هو مساعدتك على تحقيق التوازن والانسجام في حياتك، مع تقديم نصائح عملية لتحسين وضعك.",
      "تواصل معنا الآن للحصول على كشف روحاني دقيق وشامل. دعنا نكون دليلك لتحقيق النجاح والسعادة في حياتك. نحن هنا لنقدم لك الدعم الذي تحتاجه بخبرتنا الطويلة."
    ],
    benefits: [
      "تحليل روحاني شامل",
      "حلول مخصصة",
      "كشف دقيق للمشاكل",
      "نصائح عملية",
      "متابعة مستمرة",
      "نتائج سريعة وفعالة"
    ]
  }
};

const ServicePage: React.FC = () => {
  const { serviceId } = useParams<{ serviceId: string }>();
  const service = services[serviceId as keyof typeof services];
  const whatsappNumber = "212604010304";

  const handleWhatsAppClick = () => {
    const message = `السلام عليكم، أريد استشارة بخصوص خدمة ${service.title}`;
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  if (!service) {
    return <div>الخدمة غير موجودة</div>;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={0} sx={{ p: { xs: 2, md: 4 }, borderRadius: 2 }}>
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          {service.icon}
          <Typography 
            variant="h1" 
            sx={{ 
              fontSize: { xs: '2rem', md: '2.5rem' },
              fontWeight: 700,
              color: '#006633',
              mt: 2,
              fontFamily: 'Tajawal'
            }}
          >
            {service.title}
          </Typography>
        </Box>

        <Box sx={{ mb: 6 }}>
          {service.description.map((paragraph, index) => (
            <Typography
              key={index}
              sx={{
                mb: 3,
                lineHeight: 1.8,
                fontSize: '1.1rem',
                color: '#333',
                textAlign: 'right',
                fontFamily: 'Tajawal'
              }}
            >
              {paragraph}
            </Typography>
          ))}
        </Box>

        <Box sx={{ 
          backgroundColor: 'rgba(0,102,51,0.05)', 
          p: 4, 
          borderRadius: 2,
          mb: 6
        }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: '1.5rem',
              fontWeight: 700,
              color: '#006633',
              mb: 3,
              textAlign: 'right',
              fontFamily: 'Tajawal'
            }}
          >
            مميزات الخدمة
          </Typography>
          <Box sx={{ 
            display: 'grid', 
            gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
            gap: 2
          }}>
            {service.benefits.map((benefit, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  backgroundColor: 'white',
                  p: 2,
                  borderRadius: 1,
                  boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                }}
              >
                <Box
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    backgroundColor: '#006633'
                  }}
                />
                <Typography sx={{ fontFamily: 'Tajawal' }}>
                  {benefit}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            size="large"
            startIcon={<WhatsApp sx={{ ml: 1 }} />}
            onClick={handleWhatsAppClick}
            sx={{
              bgcolor: '#006633',
              fontFamily: 'Tajawal',
              fontSize: '1.1rem',
              py: 2,
              px: 6,
              '&:hover': {
                bgcolor: '#005528'
              }
            }}
          >
            احجز استشارة مع الشيخ
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default ServicePage;