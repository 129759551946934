import React from 'react';
import {
  Container,
  Box,
  Typography,
  Paper
} from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  return (
    <Box sx={{ py: { xs: 4, md: 6 }, backgroundColor: '#f8f8f8' }}>
      <Container maxWidth="md">
        <Paper
          elevation={0}
          sx={{
            p: { xs: 3, md: 5 },
            backgroundColor: 'white',
            borderRadius: 2
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '2rem', md: '2.5rem' },
              fontWeight: 700,
              textAlign: 'center',
              color: '#006633',
              mb: 4,
              fontFamily: 'Tajawal'
            }}
          >
            سياسة الخصوصية
          </Typography>

          <Box sx={{ 
            '& > *': { 
              fontFamily: 'Tajawal',
              color: '#333',
              mb: 3,
              lineHeight: 1.8,
              textAlign: 'right'
            }
          }}>
            <Typography variant="h6" sx={{ 
              color: '#006633',
              fontWeight: 600,
              fontSize: '1.3rem',
              mb: 2
            }}>
              مقدمة
            </Typography>
            <Typography>
              نحن في بوابة شيخي نلتزم بحماية خصوصية زوارنا ومستخدمينا. تشرح هذه السياسة كيفية جمع واستخدام وحماية معلوماتكم الشخصية.
            </Typography>

            <Typography variant="h6" sx={{ 
              color: '#006633',
              fontWeight: 600,
              fontSize: '1.3rem',
              mb: 2,
              mt: 4
            }}>
              المعلومات التي نجمعها
            </Typography>
            <Typography>
              نقوم بجمع المعلومات التي تقدمونها لنا مباشرة عند:
            </Typography>
            <ul style={{ paddingRight: '20px', marginTop: '8px' }}>
              <li>التواصل معنا عبر نموذج الاتصال</li>
              <li>التواصل عبر تطبيق الواتساب</li>
              <li>زيارة صفحاتنا على مواقع التواصل الاجتماعي</li>
            </ul>

            <Typography variant="h6" sx={{ 
              color: '#006633',
              fontWeight: 600,
              fontSize: '1.3rem',
              mb: 2,
              mt: 4
            }}>
              كيفية استخدام المعلومات
            </Typography>
            <Typography>
              نستخدم المعلومات التي نجمعها من أجل:
            </Typography>
            <ul style={{ paddingRight: '20px', marginTop: '8px' }}>
              <li>تقديم خدماتنا وتحسينها</li>
              <li>التواصل معكم والرد على استفساراتكم</li>
              <li>إرسال معلومات مهمة تتعلق بخدماتنا</li>
            </ul>

            <Typography variant="h6" sx={{ 
              color: '#006633',
              fontWeight: 600,
              fontSize: '1.3rem',
              mb: 2,
              mt: 4
            }}>
              حماية المعلومات
            </Typography>
            <Typography>
              نحن نتخذ جميع الإجراءات اللازمة لحماية معلوماتكم الشخصية من الوصول غير المصرح به أو الإفصاح غير المقصود.
            </Typography>

            <Typography variant="h6" sx={{ 
              color: '#006633',
              fontWeight: 600,
              fontSize: '1.3rem',
              mb: 2,
              mt: 4
            }}>
              التواصل معنا
            </Typography>
            <Typography>
              إذا كان لديكم أي أسئلة حول سياسة الخصوصية الخاصة بنا، يمكنكم التواصل معنا عبر صفحة اتصل بنا أو عبر وسائل التواصل الاجتماعي المتوفرة.
            </Typography>

            <Typography sx={{ 
              mt: 6,
              color: '#666',
              fontSize: '0.9rem',
              textAlign: 'center'
            }}>
              آخر تحديث: {new Date().toLocaleDateString('ar-MA')}
            </Typography>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy; 