import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Carousel from 'react-multi-carousel';
import ReactCountryFlag from 'react-country-flag';
import 'react-multi-carousel/lib/styles.css';

const testimonials = [
  {
    name: 'نوف',
    country: 'السعودية',
    countryCode: 'SA',
    text: 'والله يا شيخ كانت حياتي كلها عكوسات، ما ألقى شغل وماشي يتيسر لي. بعد كشف الشيخ كنجور واستخدام البخور، الحمد لله الأمور تحسنت وصرت أعيش براحة نفسية.'
  },
  {
    name: 'حمد',
    country: 'الكويت',
    countryCode: 'KW',
    text: 'كنت أحس بشي غريب في البيت، نومي ثقيل وأحلام مزعجة. لما جربت بخور روح التباطييل بناءً على نصيحة الشيخ كنجور، حسيت براحة والبيت صار طاقته نظيفة.'
  },
  {
    name: 'شيخة',
    country: 'الإمارات',
    countryCode: 'AE',
    text: 'الرقية الشرعية بصوت الشيخ أثرت فيني كثير. صرت أسمع كل يوم، والحمد لله أحس نفسي أخف وما عاد يجيني القلق اللي كان مضايقني.'
  },
  {
    name: 'محمد',
    country: 'البحرين',
    countryCode: 'BH',
    text: 'الخدمة سريعة والنتيجة واضحة. الشيخ كنجور كشف لي أمور ما كنت منتبه لها، وبعد جلسة العلاج الروحي تحسنت حالتي وصرت أنام براحة.'
  },
  {
    name: 'منيرة',
    country: 'قطر',
    countryCode: 'QA',
    text: 'الله يجزاهم خير. البخور اللي طلبته من الشيخ كنجور كان فعلاً مميز. أول ما شغلته حسيت بطاقة إيجابية تعم البيت. صراحة ما توقعت هالتغيير.'
  },
  {
    name: 'عبيد',
    country: 'السعودية',
    countryCode: 'SA',
    text: 'كنت أشك إنه في حسد مؤثر علي. بعد كشف الشيخ واستخدام الرقية والبخور، حياتي تغيرت تمامًا وصرت أشوف بركة في كل شي.'
  },
  {
    name: 'فاطمة',
    country: 'الإمارات',
    countryCode: 'AE',
    text: 'الخدمة روعة والشيخ ما قصر معاي. كل شي صار واضح لي بعد الكشف، ونصايحه ساعدتني كثير في تحسين نفسيتي وطرد السلبية.'
  },
  {
    name: 'جابر',
    country: 'الكويت',
    countryCode: 'KW',
    text: 'الحاسبة الروحانية كانت دقيقة جدًا. بعد الكشف قررت أسوي جلسة مع الشيخ كنجور، والحمد لله حسيت براحة كبيرة.'
  },
  {
    name: 'مريم',
    country: 'البحرين',
    countryCode: 'BH',
    text: 'استخدمت بخور روح التباطييل حسب إرشادات الشيخ، وفعلاً حسيت بالفرق، خصوصًا في علاقتي مع أهلي وصديقاتي.'
  },
  {
    name: 'سالم',
    country: 'قطر',
    countryCode: 'QA',
    text: 'الخدمة ممتازة والبخور كان فوق توقعاتي. صراحة حسيت بيتي صار مليان طاقة إيجابية من أول استخدام.'
  }
];

const TestimonialsSection = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <Box sx={{ 
      py: 10, 
      backgroundColor: 'white',
      mb: { xs: 8, md: 12 }
    }}>
      <Container maxWidth="lg">
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '2rem', md: '2.5rem' },
              fontWeight: 700,
              color: '#006633',
              mb: 1,
              fontFamily: 'Tajawal',
              position: 'relative',
              display: 'inline-block',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: -8,
                left: '50%',
                transform: 'translateX(-50%)',
                width: '40%',
                height: 3,
                background: 'linear-gradient(90deg, transparent, #006633, transparent)',
                borderRadius: '2px'
              }
            }}
          >
            آراء العملاء
          </Typography>

          <Typography
            sx={{
              color: '#666',
              mt: 3,
              fontFamily: 'Tajawal',
              fontSize: '1.1rem',
              maxWidth: '600px',
              mx: 'auto'
            }}
          >
            نحن فخورون بشهادات عملائنا والتي تعكس رضاهم عن خدماتنا المميزة.
          </Typography>
        </Box>

        <Box sx={{ 
          position: 'relative',
          '& .react-multi-carousel-dot-list': {
            bottom: '-40px'
          },
          '& .react-multi-carousel-dot button': {
            borderColor: '#006633',
            opacity: 0.5,
            transition: 'all 0.3s ease',
            width: 8,
            height: 8,
            borderWidth: 1,
            margin: '0 4px'
          },
          '& .react-multi-carousel-dot--active button': {
            backgroundColor: '#006633',
            opacity: 1,
            transform: 'scale(1.2)'
          }
        }}>
          <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            showDots={true}
            arrows={false}
            renderDotsOutside={true}
            dotListClass="custom-dot-list-style"
            containerClass="carousel-container"
            rtl={true}
            partialVisible={false}
            centerMode={false}
          >
            {testimonials.map((testimonial, index) => (
              <Box
                key={index}
                sx={{
                  mx: 2,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: 'white',
                  borderRadius: 3,
                  overflow: 'hidden',
                  transition: 'all 0.3s ease',
                  border: '1px solid rgba(0,102,51,0.08)',
                  '&:hover': {
                    boxShadow: '0 8px 24px rgba(0,102,51,0.08)',
                    transform: 'translateY(-2px)'
                  }
                }}
              >
                <Box
                  sx={{
                    p: 3,
                    borderBottom: '1px solid rgba(0,102,51,0.08)',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2
                  }}
                >
                  <Box sx={{ position: 'relative' }}>
                    <ReactCountryFlag
                      countryCode={testimonial.countryCode}
                      svg
                      style={{
                        width: '48px',
                        height: '48px',
                        borderRadius: '50%',
                        border: '2px solid rgba(0,102,51,0.1)',
                        padding: '2px'
                      }}
                    />
                  </Box>
                  <Box sx={{ flex: 1, textAlign: 'right' }}>
                    <Typography
                      sx={{
                        fontFamily: 'Tajawal',
                        fontWeight: 700,
                        fontSize: '1.2rem',
                        color: '#006633',
                      }}
                    >
                      {testimonial.name} — {testimonial.country}
                    </Typography>
                  </Box>
                </Box>
                
                <Box
                  sx={{
                    p: 3,
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0,102,51,0.02)'
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Tajawal',
                      fontSize: '1rem',
                      color: '#444',
                      lineHeight: 1.8,
                      position: 'relative',
                      pr: 4,
                      '&::before': {
                        content: '"❝"',
                        position: 'absolute',
                        right: -5,
                        top: -5,
                        fontSize: '2rem',
                        color: 'rgba(0,102,51,0.2)',
                        fontFamily: 'serif'
                      }
                    }}
                  >
                    {testimonial.text}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Carousel>
        </Box>
      </Container>
    </Box>
  );
};

export default TestimonialsSection; 