import React from 'react';
import { Container, Typography, Paper, Box, Button, List, ListItem } from '@mui/material';
import { Security, WhatsApp, Book } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import tahsinImage from '../assets/tahsin.jpg';

const ruqyaPages = {
  'التحصين': {
    title: "التحصين",
    subtitle: "درعك الروحي للحماية من الطاقات السلبية",
    icon: <Security sx={{ fontSize: '3rem', color: '#006633' }} />,
    intro: "الحياة مليئة بالتحديات والضغوط، وقد تكون الطاقة السلبية الناتجة عن الحسد أو العين أو السحر واحدة من أكثر العقبات التي تؤثر على سعادتنا. لهذا السبب، يعد التحصين الروحي خطوة أساسية لحماية النفس والبيت من هذه التأثيرات.",
    sections: [
      {
        title: "ما هو التحصين؟",
        content: "التحصين هو درع روحي يُبنى من خلال الأدعية والأذكار المستمدة من القرآن الكريم والسنة النبوية، بالإضافة إلى الطرق الروحانية الخاصة التي يصممها خبراء مثل الشيخ كنجور. بفضل معرفته العميقة وأدواته الفريدة، يقدم تحاصين روحانية قوية لا يمكن تجاوزها، مما يجعلها الحل الأمثل للحماية الشاملة."
      },
      {
        title: "خطوات التحصين",
        subsections: [
          {
            number: "١",
            subtitle: "المداومة على الأذكار اليومية",
            items: [
              "أذكار الصباح والمساء هي سلاحك الأول للحماية.",
              "اقرأ آية لكرسي بعد كل صلاة.",
              "ردد \"بسم الله الذي لا يضر مع اسمه شيء في الأرض ولا في السماء وهو السميع العليم\" ثلاث مرات صباحًا ومساءً."
            ]
          },
          {
            number: "٢",
            subtitle: "رقية الشيخ كنجور",
            items: [
              "رقية شرعية فريدة تستهدف إزالة أي طاقات سلبية أو تأثيرات خارجية.",
              "يمكن تخصيص رقية بناءً على حالتك الشخصية لضمان أقصى حماية."
            ]
          },
          {
            number: "٣",
            subtitle: "تنقية البيت",
            items: [
              "شغّل سورة البقرة بشكل دوري في المنزل.",
              "استخدم بخورًا مثل الكندر أو الجاوي لتعزيز الطهارة الروحية في المكان."
            ]
          },
          {
            number: "٤",
            subtitle: "التحصين الخاص",
            items: [
              "تحاصين روحانية مصممة بإتقان لحمايتك من السحر والحسد والعين.",
              "هذه التحاصين تعكس خبرة عميقة وتضمن لك حماية لا يمكن اختراقها."
            ]
          }
        ]
      },
      {
        title: "فوائد التحصين الروحي",
        items: [
          "الحماية من الحسد والعين والسحر",
          "جلب الطمأنينة والراحة النفسية",
          "تحسين العلاقات داخل البيت",
          "إزالة الطاقة السلبية وجلب البركة"
        ]
      }
    ],
    conclusion: "مع خبرة الشيخ كنجور، ستشعر بحماية مضاعفة وروحانية قوية. لا تنتظر حتى تشعر بالتعب أو الضيق لتبدأ بالتحصين. اجعل هذه العادة جزءًا من حياتك اليومية واستمتع بحياة مليئة بالسكينة والطاقة الإيجابية.\n\nتواصل الآن مع الشيخ كنجور للحصول على تحصين مخصص يناسب حالتك ويمنحك راحة البال."
  },
  'اسرار-الكتاب-والسنة': {
    title: "أسرار الكتاب والسنة",
    subtitle: "حلول شرعية للسحر والعين والحسد",
    icon: <Book sx={{ fontSize: '3rem', color: '#006633' }} />,
    intro: "السحر، الجن، العين، والحسد من أبرز القضايا التي تشغل بال الناس في العالم العربي، وخاصة في منطقة الخليج. هذه الأمور ليست مجرد خرافات، بل حقائق مذكورة في الكتاب والسنة، ولها تأثيرات حقيقية على حياة الإنسان إذا لم يتم التعامل معها بالشكل الصحيح. مع الشيخ كنجور، نقدم لكم استشارات متخصصة تستند إلى تعاليم الكتاب والسنة النبوية، لإيجاد الحلول المناسبة لهذه القضايا.",
    sections: [
      {
        title: "السحر: خطر خفي وإبطاله بالقرآن الكريم",
        content: "السحر مذكور في القرآن الكريم كوسيلة يستخدمها البعض لإيذاء الآخرين أو تعطيل حياتهم. يقول الله تعالى:\n\"وما هم بضارين به من أحد إلا بإذن الله\" (البقرة: 102).\nمع الشيخ كنجور، نعتمد على الرقية الشرعية وآيات فك السحر مثل سورة البقرة، الفاتحة، وآيات الحرق، لإزالة أثر السحر وتحقيق الشفاء الروحي.",
        items: [
          "استخدام آية الكرسي بشكل يومي لحماية النفس.",
          "الاستماع لرقية مخصصة لفك السحر بصوت الشيخ كنجور لتحقيق راحة نفسية فورية."
        ]
      },
      {
        title: "العين والحسد: تأثيرهما وطرق الوقاية",
        content: "العين حق، كما قال النبي ﷺ: \"العين تُدخل الرجل القبر والجمل القدر.\" الحسد أيضًا يمكن أن يسبب تعطيلًا في الرزق، الصحة، أو العلاقات. مع الشيخ كنجور، نقدم حلولًا متكاملة للوقاية والعلاج من تأثيرات العين والحسد.",
        items: [
          "قراءة سورة الإخلاص والمعوذتين يوميًا.",
          "تحصينات الشيخ كنجور المخصصة لجلب الطمأنينة والحماية من أي طاقة سلبية.",
          "استخدام بخور روح التباطييل لإزالة تأثير الحسد والطاقة السلبية."
        ]
      },
      {
        title: "الجن وأثره على الحياة اليومية",
        content: "الجن مذكور بوضوح في القرآن الكريم كسكان لعالم غير مرئي. قد يتسببون في بعض الأحيان في أذى للإنسان إذا لم يكن محصنًا. يقول الله تعالى:\n\"وأنه كان رجال من الإنس يعوذون برجال من الجن فزادوهم رهقًا\" (الجن: 6).\nمع الشيخ كنجور، نعتمد على الرقية الشرعية لإبعاد أي تأثير سلبي للجن أو المس.",
        items: [
          "قراءة سورة البقرة باستمرار في المنزل.",
          "استخدام دعاء التحصين الذي يقدمه الشيخ كنجور كجزء من الحل الروحي."
        ]
      },
      {
        title: "الشيخ كنجور: مرجعك الروحي الموثوق",
        content: "بخبرة عميقة في مجال الروحانيات والرقية الشرعية، يقدم الشيخ كنجور حلولًا شاملة ومستندة إلى الكتاب والسنة لمواجهة السحر، العين، والحسد، مع ضمان سرية تامة ونتائج فعالة. خدماتنا مصممة خصيصًا لتلبية احتياجات الجمهور الخليجي، مع التركيز على تقديم تجربة مريحة وآمنة."
      },
      {
        title: "لماذا تختار الشيخ كنجور؟",
        items: [
          "خبرة موثوقة: يجمع بين المعرفة الشرعية والخبرة العملية.",
          "أساليب شرعية: تعتمد كل الحلول على آيات القرآن الكريم والأدعية النبوية.",
          "خدمات مخصصة: تحليل لكل حالة بشكل فردي لضمان أفضل النتائج."
        ]
      }
    ],
    conclusion: "لا تدع السحر، الجن، العين، أو الحسد يؤثرون على حياتك. تواصل مع الشيخ كنجور اليوم للحصول على استشارة مخصصة وخطوات عملية للحماية والعلاج. استعد لحياة مليئة بالطمأنينة والطاقة الإيجابية."
  }
};

const RuqyaPage: React.FC = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const page = ruqyaPages[pageId as keyof typeof ruqyaPages];
  const whatsappNumber = "212604010304";

  const handleWhatsAppClick = () => {
    const message = `السلام عليكم، أريد الاستفسار عن ${page.title}`;
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  if (!page) {
    return <div>الصفحة غير موجودة</div>;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper 
        elevation={0} 
        sx={{ 
          p: { xs: 2, md: 6 }, 
          borderRadius: 2,
        }}
      >
        {/* Header Section */}
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          {page.icon}
          <Typography 
            variant="h1" 
            sx={{ 
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              fontWeight: 700,
              color: '#006633',
              mt: 2,
              mb: 1,
              fontFamily: 'Tajawal'
            }}
          >
            {page.title}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '1.5rem', md: '2rem' },
              fontWeight: 500,
              color: '#666',
              fontFamily: 'Tajawal'
            }}
          >
            {page.subtitle}
          </Typography>
        </Box>

        {/* Featured Image */}
        <Box 
          sx={{ 
            mb: 8,
            position: 'relative',
            height: { xs: '200px', sm: '300px', md: '400px' },
            borderRadius: 4,
            overflow: 'hidden'
          }}
        >
          <Box
            component="img"
            src={tahsinImage}
            alt="التحصين"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: 4
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.4))',
              borderRadius: 4
            }}
          />
        </Box>

        {/* Introduction */}
        <Typography
          sx={{
            mb: 8,
            lineHeight: 2,
            fontSize: '1.2rem',
            color: '#333',
            textAlign: 'right',
            fontFamily: 'Tajawal',
            maxWidth: '800px',
            mx: 'auto'
          }}
        >
          {page.intro}
        </Typography>

        {/* Main Content */}
        {page.sections.map((section, index) => (
          <Box key={index} sx={{ mb: 8 }}>
            <Typography
              variant="h3"
              sx={{
                fontSize: '1.8rem',
                fontWeight: 700,
                color: '#006633',
                mb: 4,
                textAlign: 'right',
                fontFamily: 'Tajawal',
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: -10,
                  right: 0,
                  width: 60,
                  height: 3,
                  backgroundColor: '#006633'
                }
              }}
            >
              {section.title}
            </Typography>

            {section.content && (
              <Typography
                sx={{
                  mb: 4,
                  lineHeight: 1.8,
                  fontSize: '1.1rem',
                  color: '#333',
                  textAlign: 'right',
                  fontFamily: 'Tajawal'
                }}
              >
                {section.content}
              </Typography>
            )}

            {section.items && (
              <Box sx={{ 
                display: 'grid', 
                gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                gap: 3,
                mt: 4
              }}>
                {section.items.map((item, itemIndex) => (
                  <Box
                    key={itemIndex}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      backgroundColor: 'rgba(0,102,51,0.03)',
                      p: 3,
                      borderRadius: 2,
                      '&:hover': {
                        backgroundColor: 'rgba(0,102,51,0.06)',
                        transform: 'translateY(-2px)',
                        transition: 'all 0.3s ease'
                      }
                    }}
                  >
                    <Box
                      sx={{
                        width: 8,
                        height: 8,
                        borderRadius: '50%',
                        backgroundColor: '#006633',
                        flexShrink: 0
                      }}
                    />
                    <Typography sx={{ 
                      fontFamily: 'Tajawal',
                      fontSize: '1.1rem',
                      color: '#444'
                    }}>
                      {item}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        ))}

        {/* Conclusion */}
        <Box sx={{ 
          textAlign: 'center',
          maxWidth: '800px',
          mx: 'auto',
          mb: 6,
          p: 4,
          borderRadius: 2,
          backgroundColor: 'rgba(0,102,51,0.03)'
        }}>
          <Typography
            sx={{
              lineHeight: 2,
              fontSize: '1.2rem',
              color: '#333',
              fontFamily: 'Tajawal',
              whiteSpace: 'pre-line'
            }}
          >
            {page.conclusion}
          </Typography>
        </Box>

        {/* CTA Button */}
        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            size="large"
            startIcon={<WhatsApp sx={{ ml: 1 }} />}
            onClick={handleWhatsAppClick}
            sx={{
              bgcolor: '#006633',
              fontFamily: 'Tajawal',
              fontSize: '1.2rem',
              py: 2,
              px: 8,
              borderRadius: 2,
              boxShadow: '0 4px 14px rgba(0,102,51,0.2)',
              '&:hover': {
                bgcolor: '#005528',
                transform: 'translateY(-2px)',
                transition: 'all 0.3s ease'
              }
            }}
          >
            احصل على تحصين مخصص
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default RuqyaPage; 