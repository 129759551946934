import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Alert,
  CircularProgress
} from '@mui/material';
import { Calculate } from '@mui/icons-material';

interface CalculationResult {
  severity: 'منخفضة' | 'متوسطة' | 'عالية' | 'خطيرة';
  description: string;
  recommendations: string[];
}

const MagicRemovalCalculator: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    birthDate: '',
    symptoms: '',
    duration: 'less_than_month',
    sleepIssues: 'no',
    relationshipIssues: 'no',
    nightmares: 'no'
  });

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<CalculationResult | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const calculateResult = (): CalculationResult => {
    let score = 0;
    
    // Calculate score based on duration
    if (formData.duration === 'more_than_year') score += 3;
    else if (formData.duration === 'six_months') score += 2;
    else if (formData.duration === 'one_month') score += 1;

    // Add points for various issues
    if (formData.sleepIssues === 'yes') score += 2;
    if (formData.relationshipIssues === 'yes') score += 2;
    if (formData.nightmares === 'yes') score += 2;

    // Determine severity and recommendations
    let severity: CalculationResult['severity'];
    let description: string;
    let recommendations: string[];

    if (score <= 2) {
      severity = 'منخفضة';
      description = 'الأعراض خفيفة ويمكن معالجتها بسهولة';
      recommendations = [
        'قراءة الأذكار اليومية',
        'المحافظة على الصلوات',
        'قراءة سورة البقرة'
      ];
    } else if (score <= 4) {
      severity = 'متوسطة';
      description = 'هناك مؤشرات تستدعي الاهتمام والمتابعة';
      recommendations = [
        'استشارة مختص روحاني',
        'المداومة على قراءة القرآن',
        'الرقية الشرعية الأساسية',
        'تحصين المنزل بالأذكار'
      ];
    } else if (score <= 7) {
      severity = 'عالية';
      description = 'تحتاج إلى تدخل متخصص وعلاج مركز';
      recommendations = [
        'التواصل مع معالج روحاني متخصص',
        'جلسات رقية شرعية مكثفة',
        'العلاج بالأعشاب الروحانية',
        'برنامج تحصين شامل'
      ];
    } else {
      severity = 'خطيرة';
      description = 'الحالة متقدمة وتحتاج إلى علاج عاجل ومتابعة مستمرة';
      recommendations = [
        'التواصل الفوري مع الشيخ المعالج',
        'برنامج علاجي شامل ومكثف',
        'جلسات رقية متخصصة',
        'متابعة يومية مع المعالج'
      ];
    }

    return { severity, description, recommendations };
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    // Simulate calculation delay
    setTimeout(() => {
      const calculationResult = calculateResult();
      setResult(calculationResult);
      setLoading(false);
    }, 1500);
  };

  return (
    <Box sx={{ py: { xs: 4, md: 6 }, backgroundColor: '#f8f8f8' }}>
      <Container maxWidth="md">
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '1.8rem', md: '2.2rem' },
            fontWeight: 700,
            textAlign: 'center',
            color: '#006633',
            mb: 2,
            fontFamily: 'Tajawal'
          }}
        >
          حاسبة فك السحر والعكوسات
        </Typography>

        <Typography
          sx={{
            textAlign: 'center',
            color: '#666',
            mb: 4,
            fontFamily: 'Tajawal',
            fontSize: { xs: '1rem', md: '1.1rem' }
          }}
        >
          أدخل المعلومات المطلوبة لتحليل حالتك وتحديد نوع العلاج المناسب
        </Typography>

        <Paper
          elevation={0}
          sx={{
            p: { xs: 3, md: 4 },
            backgroundColor: 'white',
            borderRadius: 2
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <TextField
                fullWidth
                label="الاسم الكامل"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                InputLabelProps={{
                  sx: { fontFamily: 'Tajawal' }
                }}
                InputProps={{
                  sx: { fontFamily: 'Tajawal' }
                }}
              />

              <TextField
                fullWidth
                label="تاريخ الميلاد"
                name="birthDate"
                type="date"
                value={formData.birthDate}
                onChange={handleChange}
                required
                InputLabelProps={{
                  shrink: true,
                  sx: { fontFamily: 'Tajawal' }
                }}
                InputProps={{
                  sx: { fontFamily: 'Tajawal' }
                }}
              />

              <TextField
                fullWidth
                label="الأعراض التي تعاني منها"
                name="symptoms"
                multiline
                rows={4}
                value={formData.symptoms}
                onChange={handleChange}
                required
                InputLabelProps={{
                  sx: { fontFamily: 'Tajawal' }
                }}
                InputProps={{
                  sx: { fontFamily: 'Tajawal' }
                }}
              />

              <FormControl>
                <FormLabel sx={{ fontFamily: 'Tajawal', color: '#006633' }}>
                  منذ متى تعاني من هذه الأعراض؟
                </FormLabel>
                <RadioGroup
                  name="duration"
                  value={formData.duration}
                  onChange={handleChange}
                >
                  <FormControlLabel 
                    value="less_than_month" 
                    control={<Radio />} 
                    label="أقل من شهر"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="one_month" 
                    control={<Radio />} 
                    label="شهر إلى ستة أشهر"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="six_months" 
                    control={<Radio />} 
                    label="ستة أشهر إلى سنة"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="more_than_year" 
                    control={<Radio />} 
                    label="أكثر من سنة"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel sx={{ fontFamily: 'Tajawal', color: '#006633' }}>
                  هل تعاني من مشاكل في النوم؟
                </FormLabel>
                <RadioGroup
                  name="sleepIssues"
                  value={formData.sleepIssues}
                  onChange={handleChange}
                >
                  <FormControlLabel 
                    value="yes" 
                    control={<Radio />} 
                    label="نعم"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="no" 
                    control={<Radio />} 
                    label="لا"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel sx={{ fontFamily: 'Tajawal', color: '#006633' }}>
                  هل تواجه مشاكل في العلاقات الاجتماعية؟
                </FormLabel>
                <RadioGroup
                  name="relationshipIssues"
                  value={formData.relationshipIssues}
                  onChange={handleChange}
                >
                  <FormControlLabel 
                    value="yes" 
                    control={<Radio />} 
                    label="نعم"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="no" 
                    control={<Radio />} 
                    label="لا"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel sx={{ fontFamily: 'Tajawal', color: '#006633' }}>
                  هل تعاني من كوابيس متكررة؟
                </FormLabel>
                <RadioGroup
                  name="nightmares"
                  value={formData.nightmares}
                  onChange={handleChange}
                >
                  <FormControlLabel 
                    value="yes" 
                    control={<Radio />} 
                    label="نعم"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                  <FormControlLabel 
                    value="no" 
                    control={<Radio />} 
                    label="لا"
                    sx={{ '& .MuiFormControlLabel-label': { fontFamily: 'Tajawal' } }}
                  />
                </RadioGroup>
              </FormControl>

              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <Calculate />}
                sx={{
                  py: 1.5,
                  backgroundColor: 'var(--primary-green)',
                  fontFamily: 'Tajawal',
                  fontSize: '1.1rem',
                  '& .MuiButton-startIcon': {
                    marginRight: 0,
                    marginLeft: '12px'
                  },
                  '&:hover': {
                    backgroundColor: 'var(--dark-green)'
                  }
                }}
              >
                {loading ? 'جاري التحليل...' : 'تحليل الحالة'}
              </Button>
            </Box>
          </form>

          {result && (
            <Box sx={{ mt: 4 }}>
              <Divider sx={{ mb: 3 }} />
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'Tajawal',
                  fontWeight: 600,
                  color: '#006633',
                  mb: 2
                }}
              >
                نتيجة التحليل
              </Typography>

              <Alert 
                severity={
                  result.severity === 'خطيرة' ? 'error' :
                  result.severity === 'عالية' ? 'warning' :
                  result.severity === 'متوسطة' ? 'info' : 'success'
                }
                sx={{ mb: 2, fontFamily: 'Tajawal' }}
              >
                <Typography sx={{ fontWeight: 600, mb: 1, fontFamily: 'Tajawal' }}>
                  درجة الحالة: {result.severity}
                </Typography>
                {result.description}
              </Alert>

              <Typography
                sx={{
                  fontFamily: 'Tajawal',
                  fontWeight: 600,
                  color: '#006633',
                  mb: 1
                }}
              >
                التوصيات:
              </Typography>
              <ul style={{ paddingRight: '20px' }}>
                {result.recommendations.map((rec, index) => (
                  <li key={index} style={{ fontFamily: 'Tajawal', marginBottom: '8px' }}>
                    {rec}
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default MagicRemovalCalculator; 