import React from 'react';
import { Container, Typography, Paper, Box, Button, Grid, Card } from '@mui/material';
import { EmojiObjects, Stars, ContactSupport, WhatsApp } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

interface BlogPageProps {
  pageId?: string;
}

const blogPages = {
  'العلاج-بالطاقة': {
    title: "العلاج بالطاقة والبخور",
    subtitle: "رحلة الشفاء الطبيعي مع الشيخ كنجور",
    icon: <EmojiObjects sx={{ fontSize: '3rem', color: '#006633' }} />,
    intro: "العلاج بالطاقة والبخور هو فن قديم يجمع بين قوة الطبيعة وحكمة الأجداد. في هذا المقال، نكشف عن أسرار هذا العلاج وكيفية استخدامه للحصول على نتائج فعالة.",
    sections: [
      {
        title: "قوة العلاج بالطاقة",
        content: "العلاج بالطاقة هو ممارسة قديمة تعتمد على تحسين تدفق الطاقة في الجسم. من خلال تقنيات خاصة وخبرة عميقة، نساعد في إعادة التوازن الطبيعي للجسم والروح.",
        items: [
          "تحسين تدفق الطاقة في الجسم",
          "إزالة الطاقات السلبية",
          "تعزيز الشفاء الطبيعي",
          "تحقيق التوازن النفسي والجسدي"
        ]
      },
      {
        title: "سر البخور في العلاج",
        content: "البخور ليس مجرد عطر جميل، بل هو أداة قوية للعلاج والتطهير. كل نوع من البخور له خصائصه الفريدة وتأثيراته العلاجية المختلفة.",
        items: [
          "بخور اللبان: لتنقية الطاقة وتعزيز التركيز",
          "المر: للحماية والتطهير الروحي",
          "العود: لتحسين المزاج وتعزيز الثقة",
          "المسك: لجلب السكينة والهدوء"
        ]
      },
      {
        title: "تقنيات العلاج المتكامل",
        content: "نستخدم مزيجاً من التقنيات العلاجية التي تجمع بين الطاقة والبخور للحصول على أفضل النتائج. هذا النهج المتكامل يضمن علاجاً شاملاً وفعالاً.",
        items: [
          "جلسات علاج الطاقة المخصصة",
          "استخدام البخور العلاجي",
          "تمارين التنفس والتأمل",
          "نصائح للممارسة اليومية"
        ]
      }
    ],
    conclusion: "مع الشيخ كنجور، ستتعلم كيفية استخدام قوة الطاقة والبخور لتحسين صحتك وحياتك. تواصل معنا اليوم لبدء رحلة الشفاء الخاصة بك."
  },
  'الفلك-والابراج': {
    title: "الفلك والأبراج",
    subtitle: "فهم أسرار النجوم وتأثيرها على حياتنا",
    icon: <Stars sx={{ fontSize: '3rem', color: '#006633' }} />,
    intro: "علم الفلك والأبراج هو علم قديم يربط بين حركة النجوم والكواكب وتأثيرها على حياتنا. من خلال فهم هذه العلاقات، يمكننا اكتشاف المزيد عن أنفسنا وعن مسار حياتنا.",
    sections: [
      {
        title: "أساسيات علم الفلك الروحاني",
        content: "علم الفلك الروحاني يجمع بين المعرفة الفلكية القديمة والحكمة الروحانية. من خلال دراسة مواقع النجوم والكواكب، نستطيع فهم التأثيرات الكونية على حياتنا اليومية.",
        items: [
          "فهم الطاقات الكونية وتأثيرها",
          "دراسة العلاقات بين الأبراج",
          "تحليل الخريطة الفلكية الشخصية",
          "اكتشاف نقاط القوة والتحديات"
        ]
      },
      {
        title: "الأبراج والشخصية",
        content: "لكل برج خصائصه وسماته الفريدة التي تؤثر على شخصية صاحبه. من خلال فهم هذه السمات، يمكننا تحسين فهمنا لأنفسنا والآخرين.",
        items: [
          "تحليل التوافق بين الأبراج",
          "فهم نقاط القوة والضعف",
          "اكتشاف المسار المهني المناسب",
          "تحسين العلاقات الشخصية"
        ]
      },
      {
        title: "التنبؤات والتوقعات",
        content: "من خلال دراسة حركة الكواكب والنجوم، يمكننا فهم الفرص والتحديات القادمة. هذه المعرفة تساعدنا في اتخاذ قرارات أفضل وتحضير أنفسنا للمستقبل.",
        items: [
          "تحليل الفترات المناسبة للمشاريع الجديدة",
          "فهم التحديات المحتملة",
          "اختيار الأوقات المناسبة للقرارات المهمة",
          "تحسين التخطيط المستقبلي"
        ]
      }
    ],
    conclusion: "مع الشيخ كنجور، اكتشف كيف يمكن لعلم الفلك والأبراج أن يكون دليلاً لك في رحلة حياتك. احصل على قراءة فلكية مخصصة تساعدك في فهم مسارك وتحقيق أهدافك."
  },
  'نصائح-وارشادات': {
    title: "نصائح وإرشادات",
    subtitle: "حكمة روحانية لحياة أفضل",
    icon: <ContactSupport sx={{ fontSize: '3rem', color: '#006633' }} />,
    intro: "في عالم مليء بالتحديات والضغوط، نحتاج جميعاً إلى إرشاد حكيم يساعدنا في تجاوز الصعاب وتحقيق التوازن. نقدم لكم مجموعة من النصائح والإرشادات المستوحاة من التراث الروحاني والخبرة العملية.",
    sections: [
      {
        title: "التوازن الروحي",
        content: "التوازن الروحي هو أساس الصحة النفسية والجسدية. من خلال ممارسات بسيطة يومية، يمكنك تحقيق هذا التوازن والحفاظ عليه.",
        items: [
          "الأذكار اليومية للحماية والبركة",
          "تمارين التأمل والاسترخاء",
          "تنقية الطاقة في المنزل",
          "بناء روتين روحاني يومي"
        ]
      },
      {
        title: "العلاقات الإيجابية",
        content: "العلاقات الصحية هي مفتاح السعادة في الحياة. تعلم كيف تبني وتحافظ على علاقات إيجابية مع من حولك.",
        items: [
          "تقوية الروابط العائلية",
          "بناء صداقات صحية",
          "تجنب العلاقات السامة",
          "تحسين التواصل مع الآخرين"
        ]
      },
      {
        title: "النجاح والتطور الشخصي",
        content: "النجاح الحقيقي يبدأ من الداخل. اكتشف كيف يمكنك تطوير ذاتك وتحقيق أهدافك بطريقة متوازنة.",
        items: [
          "تحديد الأهداف بوضوح",
          "التغلب على العقبات الداخلية",
          "تطوير الثقة بالنفس",
          "تحقيق النجاح مع الحفاظ على القيم"
        ]
      }
    ],
    conclusion: "دع الشيخ كنجور يكون مرشدك في رحلة التطور الشخصي والروحاني. احصل على نصائح وإرشادات مخصصة تناسب احتياجاتك وتساعدك في تحقيق حياة أكثر توازناً وسعادة."
  }
};

const BlogPage: React.FC<BlogPageProps> = ({ pageId }) => {
  const { pageId: urlPageId } = useParams<{ pageId: string }>();
  const currentPageId = pageId || urlPageId;
  const page = blogPages[currentPageId as keyof typeof blogPages];
  const whatsappNumber = "212604010304";

  const handleWhatsAppClick = () => {
    const message = `السلام عليكم، أريد الاستفسار عن ${page.title}`;
    const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  if (!page) {
    return <div>الصفحة غير موجودة</div>;
  }

  return (
    <Box sx={{ backgroundColor: '#f8f9fa' }}>
      {/* Hero Section with Gradient Background */}
      <Box sx={{
        background: 'linear-gradient(135deg, #006633 0%, #00995c 100%)',
        pt: { xs: 4, sm: 6, md: 10 },
        pb: { xs: 8, sm: 10, md: 16 },
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: { xs: '60px', sm: '80px', md: '100px' },
          background: 'linear-gradient(to top right, #f8f9fa 49%, transparent 51%)',
        }
      }}>
        <Container maxWidth="lg" sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={7} sx={{ order: { xs: 2, md: 1 } }}>
              <Box sx={{ 
                color: 'white', 
                textAlign: 'right',
                mt: { xs: 2, md: 0 }
              }}>
                <Typography 
                  variant="h1" 
                  sx={{ 
                    fontSize: { xs: '2rem', sm: '2.5rem', md: '3.5rem' },
                    fontWeight: 700,
                    mb: { xs: 1, md: 2 },
                    fontFamily: 'Tajawal',
                    lineHeight: 1.2
                  }}
                >
                  {page.title}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem' },
                    mb: { xs: 2, md: 4 },
                    opacity: 0.9,
                    fontFamily: 'Tajawal',
                    lineHeight: 1.5
                  }}
                >
                  {page.subtitle}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} sx={{ order: { xs: 1, md: 2 } }}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                transform: { 
                  xs: 'scale(1)',
                  sm: 'scale(1.2)',
                  md: 'scale(2)'
                },
                opacity: 0.9,
                mb: { xs: 2, md: 0 }
              }}>
                {page.icon}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Content Section */}
      <Container 
        maxWidth="lg" 
        sx={{ 
          mt: { xs: -4, sm: -6, md: -8 }, 
          mb: 8, 
          position: 'relative', 
          zIndex: 1,
          px: { xs: 2, sm: 3, md: 4 }
        }}
      >
        <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
          {/* Introduction Card */}
          <Grid item xs={12}>
            <Card sx={{ 
              p: { xs: 2, sm: 3, md: 4 }, 
              borderRadius: { xs: 2, md: 4 },
              boxShadow: '0 4px 24px rgba(0,0,0,0.06)'
            }}>
              <Typography
                sx={{
                  fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
                  lineHeight: 2,
                  color: '#333',
                  textAlign: 'right',
                  fontFamily: 'Tajawal'
                }}
              >
                {page.intro}
              </Typography>
            </Card>
          </Grid>

          {/* Content Sections - Changed to 3x1 layout */}
          {page.sections.map((section, index) => (
            <Grid item xs={12} key={index}>
              <Card sx={{ 
                height: '100%',
                borderRadius: { xs: 2, md: 4 },
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: { xs: 'none', md: 'translateY(-8px)' }
                },
                boxShadow: '0 4px 24px rgba(0,0,0,0.06)'
              }}>
                <Box sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
                  <Typography
                    variant="h3"
                    sx={{
                      fontSize: { xs: '1.5rem', sm: '1.6rem', md: '1.8rem' },
                      fontWeight: 700,
                      color: '#006633',
                      mb: { xs: 2, md: 3 },
                      textAlign: 'right',
                      fontFamily: 'Tajawal'
                    }}
                  >
                    {section.title}
                  </Typography>

                  {section.content && (
                    <Typography
                      sx={{
                        mb: 3,
                        lineHeight: 1.8,
                        fontSize: '1.1rem',
                        color: '#333',
                        textAlign: 'right',
                        fontFamily: 'Tajawal'
                      }}
                    >
                      {section.content}
                    </Typography>
                  )}

                  {section.items && (
                    <Box sx={{ 
                      display: 'grid',
                      gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
                      gap: 2 
                    }}>
                      {section.items.map((item, itemIndex) => (
                        <Box
                          key={itemIndex}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            p: 2,
                            borderRadius: 2,
                            backgroundColor: 'rgba(0,102,51,0.03)',
                            transition: 'all 0.3s ease',
                            '&:hover': {
                              backgroundColor: 'rgba(0,102,51,0.06)',
                              transform: 'translateX(-8px)'
                            }
                          }}
                        >
                          <Box
                            sx={{
                              width: 6,
                              height: 6,
                              borderRadius: '50%',
                              backgroundColor: '#006633',
                              flexShrink: 0
                            }}
                          />
                          <Typography sx={{ 
                            fontFamily: 'Tajawal',
                            fontSize: '1rem',
                            color: '#444'
                          }}>
                            {item}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* CTA Section */}
        <Box sx={{ 
          mt: { xs: 4, sm: 6, md: 8 },
          p: { xs: 3, sm: 4, md: 6 },
          borderRadius: { xs: 2, md: 4 },
          background: 'linear-gradient(135deg, #006633 0%, #00995c 100%)',
          textAlign: 'center',
          color: 'white'
        }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2.2rem' },
              fontWeight: 700,
              mb: { xs: 2, md: 3 },
              fontFamily: 'Tajawal'
            }}
          >
            هل تريد معرفة المزيد؟
          </Typography>
          <Button
            variant="contained"
            size="large"
            startIcon={<WhatsApp sx={{ ml: 1 }} />}
            onClick={handleWhatsAppClick}
            sx={{
              bgcolor: 'white',
              color: '#006633',
              fontFamily: 'Tajawal',
              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
              py: { xs: 1.5, md: 2 },
              px: { xs: 4, sm: 6, md: 8 },
              borderRadius: 2,
              boxShadow: '0 4px 14px rgba(255,255,255,0.2)',
              '&:hover': {
                bgcolor: 'rgba(255,255,255,0.9)',
                transform: 'translateY(-2px)',
                transition: 'all 0.3s ease'
              }
            }}
          >
            تواصل معنا الآن
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default BlogPage; 