import React from 'react';
import { Card, CardContent, Typography, SxProps, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ServiceCardProps {
  service: {
    title: string;
    description: string;
    icon: React.ReactNode;
    link: string;
    buttonText: string;
  };
  sx?: SxProps;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ service, sx }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    // Scroll to top smoothly
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Navigate to the link
    navigate(service.link);
  };

  return (
    <Card 
      onClick={handleClick}
      sx={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        cursor: 'pointer',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 8px 24px rgba(0,102,51,0.15)',
        },
        borderRadius: 2,
        overflow: 'hidden',
        border: '1px solid rgba(0,102,51,0.1)',
        backgroundColor: 'rgba(255,255,255,0.9)',
        ...sx
      }}
    >
      <CardContent sx={{ 
        flexGrow: 1, 
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
      }}>
        <Box
          className="icon-container"
          sx={{
            borderRadius: '50%',
            backgroundColor: 'rgba(0,102,51,0.1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 3,
            color: '#006633',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: '#006633',
              color: 'white',
              transform: 'rotate(360deg)',
            },
          }}
        >
          {service.icon}
        </Box>
        
        <Typography 
          variant="h5" 
          component="h3"
          className="service-title"
          sx={{ 
            fontFamily: 'Tajawal',
            fontWeight: 700,
            color: '#006633'
          }}
        >
          {service.title}
        </Typography>
        
        <Typography 
          variant="body1"
          className="service-description"
          sx={{ 
            fontFamily: 'Tajawal',
            color: '#444444'
          }}
        >
          {service.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ServiceCard;
